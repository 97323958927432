import { useCallback, useEffect, useMemo, useState } from 'react'
import { CustomColumn } from '@/types/react-table-config'
import { Typography } from '@/atoms/index'
import {
  ActionType,
  ModalSearchVaultResponse
} from './useModalSearchVaultData.types'
import { useVaultApi } from '../api/ecm/useVaultApi'
import { VaultItemType } from '../api/ecm/useVaultApi/useVaultApi.types'
import { useCreateOptionsUser } from '../useCreateOptionsUser'
import { useTable } from '../useTable'
import useTranslation from '../useTranslation'
import useUrlState from '../useUrlState'
import { useViewport } from '../useViewport'
import { IBreakpoint } from '../useViewport/useViewport'
import { useAuth } from '@/providers'
import { Button } from '@/ui/atoms/shadcn'

const useModalSearchVaultData = (
  handleClose: (vault: { vaultId: string; vaultName: string }) => void,
  actionType: ActionType,
  enabled = false
): ModalSearchVaultResponse => {
  const { t } = useTranslation('searchVault')

  const [selectedUserOption, setSelectedUserOption, isReadySelectedUserOption] =
    useUrlState<string | null>('userSearchVault', null)
  const { pageNumber, setPageNumber, pageSize, setPageSize } = useTable(5)
  const { breakpoint } = useViewport()
  const { isAdmin } = useAuth()

  const { useUserAccountVaults, useVaultOwners, useAllAccountUsersVaults } =
    useVaultApi()
  const { data: dataVaultOwners } = useVaultOwners(!!isAdmin && enabled)
  const {
    data: dataUserAccountVaults,
    isLoading: isLoadingUserAccountVaults,
    isSuccess: isSuccessUserAccountVaults,
    isFetching: isFetchingUserAccountVaults
  } = useUserAccountVaults(
    {
      typeFavorite: null,
      query: null
    },
    selectedUserOption,
    pageNumber,
    pageSize,
    enabled && !!selectedUserOption && selectedUserOption !== 'all'
  )
  const {
    data: dataAllAccountVaults,
    isLoading: isLoadingAllAccountVaults,
    isSuccess: isSuccessAllAccountVaults,
    isFetching: isFetchingAllAccountVaults
  } = useAllAccountUsersVaults(
    {
      typeFavorite: null,
      query: null
    },
    pageNumber,
    pageSize,
    enabled && !!selectedUserOption && selectedUserOption === 'all'
  )

  const handleResetModal = useMemo(() => {
    return () => {
      setPageNumber(0)
    }
  }, [setPageNumber])

  const currentDataTable = useMemo(() => {
    if (selectedUserOption === 'all') {
      return {
        data: dataAllAccountVaults,
        isLoading: isLoadingAllAccountVaults || isFetchingAllAccountVaults,
        isSuccess: isSuccessAllAccountVaults
      }
    }
    return {
      data: dataUserAccountVaults,
      isLoading: isLoadingUserAccountVaults || isFetchingUserAccountVaults,
      isSuccess: isSuccessUserAccountVaults
    }
  }, [
    selectedUserOption,
    dataUserAccountVaults,
    isLoadingUserAccountVaults,
    isFetchingUserAccountVaults,
    isSuccessUserAccountVaults,
    dataAllAccountVaults,
    isLoadingAllAccountVaults,
    isFetchingAllAccountVaults,
    isSuccessAllAccountVaults
  ])

  useEffect(() => {
    if (
      dataVaultOwners?.items &&
      selectedUserOption === null &&
      isReadySelectedUserOption
    ) {
      const defaultOption = dataVaultOwners?.items?.find(
        (own) => own.isCurrentUserAccount
      )
      setSelectedUserOption(defaultOption ? defaultOption.userAccountId : null)
    }
  }, [
    dataVaultOwners?.items,
    isReadySelectedUserOption,
    selectedUserOption,
    setSelectedUserOption
  ])

  const generateDynamicColumnWidths = (breakpoint: IBreakpoint) => {
    const baseConfig = {
      name: { width: '80%', minWidth: '150px' },
      action: { width: '10%', minWidth: '50px' }
    }

    const scaleFactor = {
      '3xl': 1,
      '2xl': 1,
      xl: 1,
      lg: 1,
      md: 1,
      sm: 1,
      xs: 1
    }

    const scale = scaleFactor[breakpoint] || 1

    return Object.fromEntries(
      Object.entries(baseConfig).map(([key, value]) => [
        key,
        {
          width: value.width,
          minWidth: `${parseInt(value.minWidth, 10) * scale}px`
        }
      ])
    )
  }

  const tableColumnWidth = useMemo(() => {
    return generateDynamicColumnWidths(breakpoint)
  }, [breakpoint])

  const columns: CustomColumn<VaultItemType>[] = useMemo(() => {
    return [
      {
        Header: t?.modalModalSearchVault?.tableInformation?.name || '',
        accessor: 'name',
        Cell: (value) => {
          const valuesOriginal = value?.row?.original

          return (
            <button
              className="flex flex-col flex-1 bg-transparent border-none outline-none cursor-pointer"
              type="button"
              onClick={() => {
                if (valuesOriginal?.vaultId && handleClose) {
                  handleClose({
                    vaultId: valuesOriginal?.vaultId,
                    vaultName: valuesOriginal?.name
                  })
                  handleResetModal()
                  return
                }
              }}
            >
              <Typography
                variant="text-sm-medium"
                className="hover:text-gray-500"
              >
                {valuesOriginal.name}
              </Typography>
            </button>
          )
        },
        ...tableColumnWidth.name
      },
      {
        Header: t?.modalModalSearchVault?.tableInformation?.action || '',
        accessor: 'id',
        Cell: (value) => {
          const valuesOriginal = value?.row?.original

          const labelButton = {
            envelope: t?.modalModalSearchVault?.tableInformation?.buttonSelect,
            moveTemplate:
              t?.modalModalSearchVault?.tableInformation?.buttonMoveTemplate,
            restoreRecycleBin:
              t?.modalModalSearchVault?.tableInformation?.buttonRestore,
            moveEnvelope:
              t?.modalModalSearchVault?.tableInformation?.buttonMoveEnvelope
          }[actionType as ActionType]

          const [loading, setLoading] = useState(false)

          return (
            <Button
              variant="outlineSecondary"
              size="sm"
              className="px-2 py-1 text-xs whitespace-nowrap"
              type="button"
              onClick={() => {
                setSelectedUserOption(null)
                if (valuesOriginal?.vaultId && handleClose) {
                  setLoading(true)
                  handleClose({
                    vaultId: valuesOriginal?.vaultId,
                    vaultName: valuesOriginal?.name
                  })
                  handleResetModal()
                  return
                }
              }}
              loading={loading}
            >
              {labelButton}
            </Button>
          )
        },
        ...tableColumnWidth.action
      }
    ]
  }, [
    actionType,
    handleClose,
    handleResetModal,
    setSelectedUserOption,
    t?.modalModalSearchVault?.tableInformation?.action,
    t?.modalModalSearchVault?.tableInformation?.buttonMoveEnvelope,
    t?.modalModalSearchVault?.tableInformation?.buttonMoveTemplate,
    t?.modalModalSearchVault?.tableInformation?.buttonRestore,
    t?.modalModalSearchVault?.tableInformation?.buttonSelect,
    t?.modalModalSearchVault?.tableInformation?.name,
    tableColumnWidth.action,
    tableColumnWidth.name
  ])

  const optionsUser = useCreateOptionsUser({
    users: dataVaultOwners?.items as any[]
  })

  const handleSelectOption = useCallback(
    (option) => {
      setSelectedUserOption(option)
      setPageNumber(0)
    },
    [setPageNumber, setSelectedUserOption]
  )

  return {
    pageNumber,
    setPageNumber,
    pageSize,
    setPageSize,
    currentDataTable,
    columns,
    handleResetModal,
    optionsUser,
    handleSelectOption,
    selectedUserOption,
    setSelectedUserOption
  }
}

export default useModalSearchVaultData
