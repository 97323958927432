import React, { SetStateAction, useCallback, useEffect, useMemo } from 'react'
import { TrashIcon } from '@heroicons/react/24/outline'
import { zodResolver } from '@hookform/resolvers/zod'
import { UseQueryResult } from '@tanstack/react-query'
import { useForm, useWatch } from 'react-hook-form'
import { toast } from 'react-toastify'
import { z } from 'zod'
import useTranslation from '@/hooks/useTranslation'
import {
  ButtonWithTooltip,
  Form,
  Modal,
  RenderFormActions,
  RenderFormField
} from '..'
import { useWebhooksApi } from '@/hooks/api/core/useWebhooksApi'
import { GetWebhooksCLIResponse } from '@/hooks/api/core/useWebhooksApi/useWebhooksApi.types'
import {
  ModalActionType,
  WebhooksCLIFormType,
  WebhooksFormType
} from '@/hooks/useWebhooksData/useWebhooksData.types'
import { MultiSelect } from '@/ui/atoms'
import { Button } from '@/ui/atoms/shadcn'

type Props = {
  id?: string | null
  action: ModalActionType['action']
  isOpen: boolean
  onClose: () => void
  onSubmit: (
    action: ModalActionType['action'],
    values: WebhooksFormType
  ) => Promise<void> | void
  isLoadingSubmit?: boolean
  setModalAction: React.Dispatch<SetStateAction<ModalActionType>>
  dataCLI: UseQueryResult<GetWebhooksCLIResponse | null, unknown>
  setModalError: React.Dispatch<React.SetStateAction<boolean>>
}

const ModalActionWebhooks: React.FC<Props> = ({
  id,
  isOpen,
  action,
  onClose,
  onSubmit,
  isLoadingSubmit = false,
  setModalAction,
  dataCLI,
  setModalError
}) => {
  const { t, isReady } = useTranslation(
    ['accountWebhooks', 'validations'],
    true
  )
  const { useGetWebhook, useUpdateWebhooksCLI } = useWebhooksApi()
  const { data, isLoading: isLoadingGet } = useGetWebhook(
    isOpen && (action === 'update' || action === 'view'),
    id || null
  )
  const { mutateAsync: mutateAsyncCLI, isLoading: isLoadingUpdateCLI } =
    useUpdateWebhooksCLI()

  const urlRegex = /^(https?:\/\/[^\s$.?#].[^\s]*)$/

  const formSchema = z.object({
    name: z
      .string()
      .min(1, { message: t?.requiredField })
      .min(5, { message: t?.errorMinLength?.(5) })
      .max(200, { message: t?.errorMaxLength?.(200) }),
    description: z
      .string()
      .max(4000, { message: t?.errorMaxLength?.(4000) })
      .optional(),
    url: z
      .string()
      .min(1, { message: t?.requiredField })
      .max(200, { message: t?.errorMaxLength?.(200) })
      .regex(urlRegex, { message: t?.invalidUrl }),
    httpMethod: z.string().min(1, { message: t?.requiredField }),
    authMethod: z.string().min(1, { message: t?.requiredField }),
    eventTypes: z.array(z.string()).min(1, { message: t?.requiredField })
  })

  const formSchemaCLI = z.object({
    url: z
      .string()
      .min(1, { message: t?.requiredField })
      .max(200, { message: t?.errorMaxLength?.(200) })
      .regex(urlRegex, { message: t?.invalidUrl }),
    isActive: z.boolean()
  })

  const form = useForm<WebhooksFormType>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: '',
      description: '',
      url: '',
      httpMethod: 'Get',
      authMethod: 'HookdeckSignature',
      eventTypes: []
    }
  })
  const name = useWatch({
    control: form.control,
    name: 'name',
    defaultValue: ''
  })

  const formCLI = useForm<WebhooksCLIFormType>({
    resolver: zodResolver(formSchemaCLI),
    defaultValues: {
      url: '',
      isActive: false
    }
  })

  useEffect(() => {
    if ((action === 'update' || action === 'view') && data) {
      form.reset({
        name: data?.name || '',
        description: data?.description || '',
        url: data?.url || '',
        httpMethod: data?.httpMethod || 'Get',
        authMethod: data?.authMethod || 'HookdeckSignature',
        eventTypes: data?.eventTypes || []
      })
      return
    }
    if (action === 'editCLI' && !!dataCLI && dataCLI?.isFetched) {
      formCLI.reset({
        url: dataCLI?.data?.url || '',
        isActive: dataCLI?.data?.isActive || false
      })
    }
  }, [action, form, data, dataCLI, formCLI])

  const showSkeleton = useMemo(() => {
    return (
      !isReady ||
      (action === 'editCLI' && !dataCLI?.isFetched) ||
      ((action === 'view' || action === 'update') && isLoadingGet) ||
      (action === 'view' && !name)
    )
  }, [action, dataCLI?.isFetched, isLoadingGet, isReady, name])

  const handleSubmitEditCLI = useCallback(
    async (values: WebhooksCLIFormType) => {
      try {
        await mutateAsyncCLI({
          isActive: dataCLI?.data?.isActive || false,
          url: values?.url || ''
        })
        onClose()
        toast.success(t?.toast?.successEditCLI)
      } catch (error: any) {
        console.error('handleSubmitEditCLI', error)
        const status = error?.response?.status
        onClose()
        if (status === 402) {
          setModalError(true)
        }
        toast.error(t?.toast?.errorEditCLI)
      }
    },
    [
      dataCLI?.data?.isActive,
      mutateAsyncCLI,
      onClose,
      setModalError,
      t?.toast?.errorEditCLI,
      t?.toast?.successEditCLI
    ]
  )

  const renderForm = useCallback(() => {
    if (action === 'editCLI') {
      return (
        <Form<WebhooksCLIFormType> {...formCLI} onSubmit={handleSubmitEditCLI}>
          <RenderFormField
            control={formCLI.control}
            name="url"
            type="input"
            showSkeleton={showSkeleton}
            {...formCLI.formState.errors.url}
            {...t?.modal?.form?.url}
          />
          <RenderFormField
            control={formCLI.control}
            name="isActive"
            type="toggle"
            showSkeleton={showSkeleton}
            {...t?.modal?.form?.isActive}
            {...formCLI.formState.errors.isActive}
          />
          <RenderFormActions align="end">
            <Button
              type="button"
              variant="neutral"
              onClick={onClose}
              showSkeleton={showSkeleton}
            >
              {t?.modal?.form?.buttonCancel}
            </Button>
            <Button
              type="submit"
              showSkeleton={showSkeleton}
              loading={isLoadingUpdateCLI}
            >
              {t?.modal?.form?.buttonConfirm}
            </Button>
          </RenderFormActions>
        </Form>
      )
    }
    return (
      <Form<WebhooksFormType>
        {...form}
        onSubmit={(values) => onSubmit(action, values)}
      >
        <RenderFormField
          control={form.control}
          name="name"
          type="input"
          disabled={action === 'view'}
          showSkeleton={showSkeleton}
          {...form.formState.errors.name}
          {...t?.modal?.form?.name}
        />
        <RenderFormField
          control={form.control}
          name="description"
          type="textarea"
          disabled={action === 'view'}
          showSkeleton={showSkeleton}
          {...form.formState.errors.description}
          {...t?.modal?.form?.description}
        />
        <RenderFormField
          control={form.control}
          name="url"
          type="input"
          disabled={action === 'view'}
          showSkeleton={showSkeleton}
          {...form.formState.errors.url}
          {...t?.modal?.form?.url}
        />
        <RenderFormField
          control={form.control}
          name="eventTypes"
          type="multiSelect"
          disabled={action === 'view'}
          showSkeleton={showSkeleton}
          {...form.formState.errors.eventTypes}
          {...t?.modal?.form?.eventTypes}
          render={({ field, fieldState }) => (
            <MultiSelect
              {...field}
              label={t?.modal?.form?.eventTypes?.label}
              options={t?.modal?.form?.eventTypes?.options}
              onValueChange={(value) => field.onChange(value)}
              placeholder={t?.modal?.form?.eventTypes?.placeholder}
              error={fieldState.error?.message}
              disabled={action === 'view'}
            />
          )}
        />
        <RenderFormField
          control={form.control}
          name="httpMethod"
          type="select"
          disabled={action === 'view'}
          showSkeleton={showSkeleton}
          {...form.formState.errors.httpMethod}
          {...t?.modal?.form?.httpMethod}
        />
        <RenderFormField
          control={form.control}
          name="authMethod"
          type="select"
          disabled={action === 'view'}
          showSkeleton={showSkeleton}
          {...form.formState.errors.authMethod}
          {...t?.modal?.form?.authMethod}
        />
        <RenderFormActions align={action === 'view' ? 'between' : 'end'}>
          {action === 'create' && (
            <>
              <Button
                type="button"
                variant="neutral"
                onClick={onClose}
                showSkeleton={showSkeleton}
              >
                {t?.modal?.form?.buttonCancel}
              </Button>
              <Button
                type="submit"
                showSkeleton={showSkeleton}
                loading={isLoadingSubmit}
              >
                {t?.modal?.form?.buttonConfirm}
              </Button>
            </>
          )}
          {action === 'update' && (
            <>
              <Button
                type="button"
                variant="neutral"
                onClick={onClose}
                showSkeleton={showSkeleton}
              >
                {t?.modal?.form?.buttonCancel}
              </Button>
              <Button
                type="submit"
                showSkeleton={showSkeleton}
                loading={isLoadingSubmit}
              >
                {t?.modal?.form?.buttonConfirm}
              </Button>
            </>
          )}
          {action === 'view' && (
            <>
              <Button
                type="button"
                onClick={() => {
                  setModalAction({
                    isOpen: true,
                    action: 'update',
                    id: id as string
                  })
                }}
                showSkeleton={showSkeleton}
              >
                {t?.modal?.form?.buttonUpdate}
              </Button>
              <Button
                type="button"
                variant="neutral"
                onClick={onClose}
                showSkeleton={showSkeleton}
              >
                {t?.modal?.form?.buttonClose}
              </Button>
            </>
          )}
        </RenderFormActions>
      </Form>
    )
  }, [
    action,
    form,
    formCLI,
    handleSubmitEditCLI,
    id,
    isLoadingSubmit,
    isLoadingUpdateCLI,
    onClose,
    onSubmit,
    setModalAction,
    showSkeleton,
    t?.modal?.form?.authMethod,
    t?.modal?.form?.buttonCancel,
    t?.modal?.form?.buttonClose,
    t?.modal?.form?.buttonConfirm,
    t?.modal?.form?.buttonUpdate,
    t?.modal?.form?.description,
    t?.modal?.form?.eventTypes,
    t?.modal?.form?.httpMethod,
    t?.modal?.form?.name,
    t?.modal?.form?.url
  ])

  return (
    <Modal
      isOpen={isOpen}
      title={t?.modal?.title?.(action)}
      onClose={onClose}
      displayAsDrawerOnMobile
    >
      {renderForm()}
    </Modal>
  )
}

export default ModalActionWebhooks
