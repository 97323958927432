export type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH'

export type QueryKeyElementType = string | object

export type QueryKeyElement = QueryKeyElementType[]

export enum QueryKeys {
  RequestAuthMetadata = 'requestAuthMetadata',
  UserContext = 'userContext',
  DashboardHomeStatisticsUserAccount = 'DashboardHomeStatisticsUserAccount',
  DashboardHomeStatisticsAccount = 'DashboardHomeStatisticsAccount',
  DashboardHomeSignerActionsOwners = 'dashboardHomeSignerActionsOwners',
  DashboardHomeSignerActionAccounts = 'dashboardHomeSignerActionAccounts',
  HistorySignerActionsOwners = 'historySignerActionsOwners',
  HistorySignerActionAccounts = 'historySignerActionAccounts',
  RecycleBinListOwners = 'recycleBinListOwners',
  RecycleBinListEnvelopeAccounts = 'recycleBinListEnvelopeAccounts',
  RecycleBinEnvelopesUserAccounts = 'recycleBinEnvelopesUserAccounts',
  RecycleBinListTemplatesAccounts = 'recycleBinListTemplatesAccounts',
  RecycleBinTemplatesUserAccounts = 'recycleBinTemplatesUserAccounts',
  AuditsSignerActionsOwners = 'auditsSignerActionsOwners',
  AuditsSignerActionAccounts = 'auditsSignerActionAccounts',
  AuditsSignerActionUserAccounts = 'auditsSignerActionUserAccounts',
  InboxData = 'inboxData',
  InboxSignatureLink = 'inboxSignatureLink',
  CommandPalletSearchBar = 'commandPalletSearchBar',
  UserAccountsActive = 'userAccountsActive',
  UserAccountsInactive = 'userAccountsInactive',
  UsersInvite = 'usersInvite',
  Envelopes = 'envelopes',
  VaultsList = 'vaultsList',
  OriginalDocument = 'originalDocument',
  PublishEnvelope = 'publishEnvelope',
  Envelope = 'envelope',
  PreReviewEnvelope = 'preReviewEnvelope',
  ReviewEnvelope = 'reviewEnvelope',
  RequestEmailMFA = 'requestEmailMFA',
  RequestSmsMFA = 'requestSmsMFA',
  ApproveEnvelope = 'approveEnvelope',
  RejectEnvelope = 'rejectEnvelope',
  VaultsOwners = 'vaultsOwners',
  UserAccountVaults = 'userAccountVaults',
  UserAccountContacts = 'userAccountContacts',
  ApproveSignMark = 'approveSignMark',
  Contact = 'contact',
  Vault = 'vault',
  ProfileSettingsNotifications = 'profileSettingsNotifications',
  AccountSettingsNotifications = 'accountSettingsNotifications',
  ProfilePassword = 'profilePassword',
  BillingInvoices = 'billingInvoices',
  BillingGetLinkCheckout = 'billingGetLinkCheckout',
  BillingGetLinkReceipt = 'billingGetLinkReceipt',
  BillingGetLinkNfe = 'billingGetLinkNfe',
  BillingGetCurrentSubscription = 'billingGetCurrentSubscription',
  BillingGetListAvailablePlans = 'billingGetListAvailablePlans',
  AccountSettingsFiscal = 'accountSettingsFiscal',
  Countries = 'countries',
  States = 'states',
  Cities = 'cities',
  ProfileGeneral = 'profileGeneral',
  ProfileGeneralAvatar = 'profileGeneralAvatar',
  BillingGetCheckoutMfaCredits = 'billingGetCheckoutMfaCredits',
  BillingReleaseSubscription = 'billingReleaseSubscription',
  SignatureMarks = 'signatureMarks',
  RubricMarks = 'rubricMarks',
  BillingUpdateAddonApiEnvelope = 'billingUpdateAddonApiEnvelope',
  BillingUpdateAddonWhiteLabel = 'billingUpdateAddonWhiteLabel',
  BillingChangeSubscription = 'billingChangeSubscription',
  Templates = 'templates',
  TemplatesOwners = 'templatesOwners',
  Template = 'template',
  HtmlToPdf = 'htmlToPdf',
  CreateDocumentWithTemplate = 'createDocumentWithTemplate',
  EnvelopesOwners = 'envelopesOwners',
  EnvelopesAllAccounts = 'envelopesAllAccounts',
  EnvelopesUserAccount = 'envelopesUserAccount',
  AllAccountUsersVaults = 'allAccountUsersVaults',
  AllAccountUsersTemplates = 'allAccountUsersTemplates',
  AuditDetails = 'auditDetails',
  HistorySignerActionDetails = 'historySignerActionDetails',
  PublicEnvelope = 'publicEnvelope',
  SignedDocument = 'signedDocument',
  CertificateEnvelope = 'certificateEnvelope',
  RegisterGoogle = 'registerGoogle',
  LoginGoogle = 'loginGoogle',
  UserInvite = 'userInvite',
  Invite = 'invite',
  AddressByCep = 'addressByCep',
  BillingGetLinkPendingInvoice = 'billingGetLinkPendingInvoice',
  ListNotifications = 'listNotifications',
  ListUserAccounts = 'listUserAccounts',
  ListVaultsSelect = 'listVaultsSelect',
  ProfileLanguage = 'profileLanguage',
  ProfileTheme = 'profileTheme',
  ListUsersSelect = 'listUsersSelect',
  VaultsMembers = 'vaultsMembers',
  BillingGetLinkChangePaymentMethod = 'billingGetLinkChangePaymentMethod',
  DashboardHomeThird = 'dashboardHomeThird',
  GetDocuments = 'getDocuments',
  AccountSustainabilityCertificates = 'accountSustainabilityCertificates',
  ApiTokens = 'apiTokens',
  WebhooksActive = 'webhooksActive',
  WebhooksInactive = 'webhooksInactive',
  Webhook = 'webhook',
  WebhooksCLI = 'webhooksCLI'
}

export type Pagination = {
  totalItems: number
  pageSize: number
  pageNumber: number
  pageItems: number
}
