import { useCallback, useEffect, useState, memo } from 'react'
import { InformationCircleIcon } from '@heroicons/react/24/outline'
import classNames from 'classnames'
import useTranslation from '@/hooks/useTranslation'
import { Label } from '../shadcn'
import { cn } from '@/lib/utils'
import Avatar from '@/ui/atoms/Avatar'
import * as SelectShadcn from '@/ui/atoms/shadcn/Select'
import { IconWithTooltip } from '@/ui/molecules'

export type SelectOption = {
  label: string
  value: string
  avatar?: string | boolean
  disabled?: boolean
}

export type SelectGroupOption = {
  label: string
  options: SelectOption[]
}

export type SelectProps = {
  id?: string
  name?: string
  error?: string
  size?: 'sm' | 'md'
  className?: string
  value: string | null
  onChange: (value: string) => void
  options: SelectOption[] | SelectGroupOption[]
  placeholder?: string
  disabled?: boolean
  menuPlacement?: 'top' | 'bottom'
  showSkeleton?: boolean
  timeoutSkeleton?: number
  label?: string
  inline?: boolean
  fullWidth?: boolean
  required?: boolean
  tooltipInfo?: string
}

const renderOptions = (
  options: SelectOption[] | SelectGroupOption[],
  isDisabled: boolean
) => {
  if (!options) return null
  return options.map((item, id) => {
    if ('options' in item) {
      return (
        <SelectShadcn.SelectGroup
          key={`${item.label}-${id}`}
          className="py-1.5"
        >
          {item.label && (
            <SelectShadcn.SelectLabel>{item.label}</SelectShadcn.SelectLabel>
          )}
          {item.options.map((option, id) => {
            if (!option) return null
            const itemClasses = classNames('py-1.5 cursor-pointer', {
              'focus:bg-gray-50 focus:text-secondary-700': !isDisabled,
              'text-gray-500 bg-gray-200 cursor-not-allowed': isDisabled
            })
            return (
              <SelectShadcn.SelectItem
                key={`${option.value}-${id}`}
                value={option.value}
                className={itemClasses}
              >
                <div className="flex items-center gap-3">
                  {option.avatar && option.label && (
                    <Avatar
                      name={option.label}
                      imgSrc={
                        typeof option.avatar !== 'boolean'
                          ? option.avatar
                          : undefined
                      }
                      size="sm"
                    />
                  )}
                  {option.label}
                </div>
              </SelectShadcn.SelectItem>
            )
          })}
        </SelectShadcn.SelectGroup>
      )
    } else {
      const option = item as SelectOption
      const itemClasses = classNames('py-1.5 cursor-pointer', {
        'focus:bg-gray-50 focus:text-secondary-700': !isDisabled,
        'text-gray-500 bg-gray-200 cursor-not-allowed': isDisabled
      })
      return (
        <SelectShadcn.SelectItem
          key={`${option.value}-${id}`}
          value={option.value}
          className={itemClasses}
          disabled={option.disabled}
        >
          <div className="flex items-center gap-3">
            {option.avatar && (
              <Avatar
                name={option.label}
                imgSrc={
                  typeof option.avatar !== 'boolean' ? option.avatar : undefined
                }
                size="sm"
              />
            )}
            {option.label}
          </div>
        </SelectShadcn.SelectItem>
      )
    }
  })
}

const Select: React.FC<SelectProps> = ({
  error,
  className,
  size = 'md',
  value,
  onChange,
  options,
  placeholder,
  disabled = false,
  menuPlacement = 'bottom',
  inline,
  showSkeleton,
  timeoutSkeleton,
  tooltipInfo,
  required = true,
  ...props
}) => {
  const { t } = useTranslation('validations')
  const [valueState, setValueState] = useState<string | undefined>(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    if (value && value !== valueState) {
      setValueState(value)
    }
  }, [value, valueState])

  useEffect(() => {
    if (!timeoutSkeleton) {
      setIsLoading(false)
      return
    }
    const timer = setTimeout(() => {
      setIsLoading(false)
    }, timeoutSkeleton)
    return () => clearTimeout(timer)
  }, [timeoutSkeleton])

  const containerStyle = inline
    ? 'flex items-center space-x-2'
    : 'flex flex-col'

  const handleChange = useCallback(
    (value) => {
      onChange(value)
      setValueState(value)
    },
    [onChange]
  )

  if (showSkeleton || isLoading) {
    const label = {
      sm: 'w-16 h-3',
      md: 'w-24 h-4'
    }[size || 'md']
    const select = {
      sm: 'w-16 h-8',
      md: 'w-full min-w-44 h-10'
    }[size || 'md']

    return (
      <div
        className={cn(
          'flex w-full gap-1',
          inline ? 'flex-row items-center' : 'flex-col',
          className
        )}
      >
        <div className={cn('skeleton', label)} />
        <div className={cn('skeleton', select)} />
      </div>
    )
  }

  return (
    <div
      className={cn(
        'relative w-full flex justify-end flex-col gap-1',
        props.fullWidth ? 'max-w-full' : 'w-full md:w-56',
        error && 'border-error-500 hover:border-error-500',
        inline ? 'flex-row items-center justify-start' : 'flex-col',
        containerStyle,
        className
      )}
    >
      {props.label && (
        <Label htmlFor={props.id}>
          {props.label}
          {!required && props.label && (
            <span className="text-xs font-light leading-3">
              ({t?.optional})
            </span>
          )}
          {tooltipInfo && (
            <IconWithTooltip
              icon={<InformationCircleIcon className="w-4 h-4 text-primary" />}
              tooltipText={tooltipInfo}
            />
          )}
        </Label>
      )}
      <div className="relative">
        <SelectShadcn.Select
          onValueChange={handleChange}
          value={valueState !== '' ? valueState : undefined}
          disabled={disabled}
        >
          <SelectShadcn.SelectTrigger
            id={props.id}
            className={cn(
              'w-full min-w-0 flex item-center gap-2',
              size === 'sm' ? 'h-8' : 'h-10',
              error
                ? 'border-error-500 hover:border-error-500 text-error-500'
                : 'border-gray-300',
              disabled ? 'text-gray-500 cursor-not-allowed' : '',
              className
            )}
            aria-disabled={disabled}
          >
            <SelectShadcn.SelectValue placeholder={placeholder} />
          </SelectShadcn.SelectTrigger>
          <SelectShadcn.SelectContent side={menuPlacement}>
            {!disabled && renderOptions(options, disabled)}
            {disabled && renderOptions(options, disabled)}
          </SelectShadcn.SelectContent>
        </SelectShadcn.Select>
        {error && (
          <div className="absolute left-0 mt-1 text-xs text-error-500">
            {error}
          </div>
        )}
      </div>
    </div>
  )
}

export default memo(Select)
