import { useState, useRef, ComponentProps, useCallback } from 'react'
import { CheckIcon, ClipboardIcon } from '@heroicons/react/24/outline'
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import {
  Label,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from '../shadcn'
import { cn } from '@/lib/utils'
import { IconWithTooltip } from '@/ui/molecules'

type InputTokenApiProps = ComponentProps<'input'> & {
  label?: string
  showSkeleton?: boolean
  tooltipCopy?: string
  tooltipShow?: string
  tooltipHide?: string
}

const InputTokenApi: React.FC<InputTokenApiProps> = ({
  label,
  type = 'password',
  className,
  value,
  showSkeleton = false,
  tooltipCopy = 'Copy',
  tooltipShow = 'Show',
  tooltipHide = 'Hide',
  ...props
}) => {
  const [showToken, setShowToken] = useState(false)
  const [copied, setCopied] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)

  const toggleShowToken = useCallback(() => {
    setShowToken((prevState) => !prevState)
  }, [])

  const handleCopy = useCallback(() => {
    console.log('handleCopy', value)
    navigator.clipboard.writeText(value as string)
    setCopied(true)
    setTimeout(() => setCopied(false), 2000)
  }, [value])

  if (showSkeleton) {
    return (
      <div className="flex flex-col w-full gap-1">
        <div className="w-24 h-3 md:h-4 skeleton" />
        <div className="w-full h-8 min-w-44 md:h-10 skeleton" />
      </div>
    )
  }

  return (
    <>
      <div className={cn('relative w-full flex justify-end flex-col gap-1')}>
        <Label htmlFor={props.id}>{label}</Label>
        <div className="relative">
          <input
            className={cn(
              'bg-accent-100 text-accent-900 dark:bg-accent-500 placeholder:text-gray-400 ring-0 border border-gray-300 w-full text-sm px-[13px] h-10 rounded-md shadow-sm font-normal leading-tight block outline-none hover:border-primary-200 focus:border-primary-700 focus:ring-primary-700 cursor-auto',
              className
            )}
            type={showToken ? 'text' : type}
            ref={inputRef}
            value={value}
            readOnly
            {...props}
          />
          <TooltipProvider delayDuration={50}>
            <Tooltip>
              <TooltipTrigger asChild>
                <div
                  className="absolute inset-y-0 flex items-center cursor-pointer right-10"
                  onClick={handleCopy}
                  {...props}
                >
                  {copied ? (
                    <CheckIcon className="w-5 h-5 text-green-500" />
                  ) : (
                    <ClipboardIcon className="w-5 h-5 text-gray-400" />
                  )}
                </div>
              </TooltipTrigger>
              <TooltipContent className="max-w-sm" sideOffset={0}>
                {tooltipCopy}
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
          <TooltipProvider delayDuration={50}>
            <Tooltip>
              <TooltipTrigger asChild>
                <div
                  className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                  onClick={toggleShowToken}
                  {...props}
                >
                  {showToken ? (
                    <FaEyeSlash className="w-5 h-5 text-gray-400" />
                  ) : (
                    <FaEye className="w-5 h-5 text-gray-400" />
                  )}
                </div>
              </TooltipTrigger>
              <TooltipContent className="max-w-sm" sideOffset={0}>
                {showToken ? tooltipHide : tooltipShow}
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      </div>
    </>
  )
}

export default InputTokenApi
