import React, { useMemo } from 'react'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import useTranslation from '@/hooks/useTranslation'
import { useViewport } from '@/hooks/useViewport'
import { cn } from '@/lib/utils'
import { Typography } from '@/ui/atoms'
import { Button } from '@/ui/atoms/shadcn'

type PaginationProps = {
  canPreviousPage: boolean
  canNextPage: boolean
  setPageNumber: (pageNumber: number) => void
  pageNumber: number
  totalPages: number
  totalItems: number
  pageSize: number
  showSkeleton?: boolean
  className?: string
}

const Pagination: React.FC<PaginationProps> = ({
  canPreviousPage,
  canNextPage,
  setPageNumber,
  pageNumber,
  totalPages,
  pageSize,
  totalItems,
  showSkeleton = false,
  className
}) => {
  const { t } = useTranslation('table')
  const pageNumbers = generatePageNumbers(pageNumber + 1, totalPages)
  const { breakpoint } = useViewport()

  const isMobile = useMemo(
    () => breakpoint === 'xs' || breakpoint === 'sm',
    [breakpoint]
  )

  if (showSkeleton) {
    return (
      <div
        className={cn(
          'flex flex-row items-center justify-center w-full gap-2 md:justify-end md:gap-4',
          className
        )}
      >
        {!isMobile && <div className="h-4 w-28 skeleton" />}
        <div className="flex items-center gap-2">
          {Array.from({ length: 3 }).map((_, index) => (
            <div key={index} className="w-8 h-8 skeleton" />
          ))}
        </div>
      </div>
    )
  }

  return (
    <div
      className={cn(
        'flex flex-row items-center justify-center w-full gap-2 md:justify-end md:gap-4',
        className
      )}
    >
      <Typography variant="text-sm-medium" className="min-w-max">
        {!isMobile && `${t?.pagination?.display}`} {pageNumber * pageSize + 1} -{' '}
        {Math.min((pageNumber + 1) * pageSize, totalItems || 0)}{' '}
        {t?.pagination?.of} {totalItems}{' '}
        {totalItems <= 1 ? t?.pagination?.item : t?.pagination?.items}
      </Typography>
      <div className="flex items-center gap-2">
        <Button
          variant="ghost"
          onClick={() => setPageNumber(pageNumber - 1)}
          disabled={!canPreviousPage}
          className="flex items-center justify-center w-8 h-8 p-0 lg:p-0 md:w-10 md:h-10"
        >
          <ChevronLeftIcon className="w-4 h-4" />
        </Button>
        {isMobile && (
          <Button
            variant="secondary"
            className="flex items-center justify-center w-8 h-8 p-0 lg:p-0 md:w-10 md:h-10"
          >
            {pageNumber + 1}
          </Button>
        )}
        {!isMobile &&
          pageNumbers.map((page) => (
            <Button
              key={page}
              onClick={() => setPageNumber(page - 1)}
              variant={pageNumber + 1 === page ? 'secondary' : 'ghost'}
              className="flex items-center justify-center w-8 h-8 p-0 lg:p-0 md:w-10 md:h-10"
            >
              {page}
            </Button>
          ))}
        <Button
          variant="ghost"
          onClick={() => setPageNumber(pageNumber + 1)}
          disabled={!canNextPage}
          className="flex items-center justify-center w-8 h-8 p-0 lg:p-0 md:w-10 md:h-10"
        >
          <ChevronRightIcon className="w-4 h-4" />
        </Button>
      </div>
    </div>
  )
}

export default Pagination

const generatePageNumbers = (
  currentPage: number,
  totalPages: number
): number[] => {
  const pageNumbers: number[] = [currentPage]
  let lowerPage = currentPage - 1
  let upperPage = currentPage + 1

  while (pageNumbers.length < Math.min(5, totalPages)) {
    if (lowerPage > 0) {
      pageNumbers.unshift(lowerPage)
      lowerPage--
    }
    if (upperPage <= totalPages) {
      pageNumbers.push(upperPage)
      upperPage++
    }
    if (lowerPage === 0 && upperPage > totalPages) {
      break
    }
  }

  return pageNumbers
}
