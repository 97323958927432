import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import useApi from '@/hooks/useApi'
import {
  CreateApiTokenRequest,
  CreateApiTokenResponse,
  GetApiTokenResponse,
  GetApiTokensResponse,
  UpdateApiTokenRequest,
  UpdateApiTokenRequestWithId
} from './useApiTokens.types'
import { QueryKeys } from '@/hooks/useApi/useApi.types'
import { useAuth } from '@/providers'

const useApiTokens = () => {
  const { handleApi, queryConfig } = useApi()
  const queryClient = useQueryClient()
  const { isLogged } = useAuth()

  // GET /v1/administration/user-account-accesses/api-tokens
  const useGetApiTokens = (pageNumber = 0, pageSize = 10) =>
    useQuery({
      queryKey: [QueryKeys.ApiTokens, pageNumber, pageSize],
      queryFn: () =>
        handleApi<void, GetApiTokensResponse>(
          `/administration/user-account-accesses/api-tokens?pageNumber=${
            pageNumber + 1
          }&pageSize=${pageSize}`,
          'GET'
        ),
      ...queryConfig,
      enabled: !!isLogged
    })

  // GET /v1/administration/user-account-accesses/api-tokens/{id}
  const useGetApiToken = (id: string | null) =>
    useQuery({
      queryKey: [QueryKeys.ApiTokens, id],
      queryFn: () =>
        handleApi<void, GetApiTokenResponse>(
          `/administration/user-account-accesses/api-tokens/${id}`,
          'GET'
        ),
      ...queryConfig,
      enabled: !!isLogged && !!id
    })

  // POST /v1/administration/user-account-accesses/api-tokens
  const useCreateApiToken = () =>
    useMutation({
      mutationFn: (data: CreateApiTokenRequest) => {
        return handleApi<CreateApiTokenRequest, CreateApiTokenResponse>(
          '/administration/user-account-accesses/api-tokens',
          'POST',
          data
        )
      },
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.ApiTokens]
        })
      }
    })

  // PUT /v1/administration/user-account-accesses/api-tokens/{id}
  const useUpdateApiToken = () =>
    useMutation({
      mutationFn: ({ id, ...data }: UpdateApiTokenRequestWithId) => {
        return handleApi<UpdateApiTokenRequest, void>(
          `/administration/user-account-accesses/api-tokens/${id}`,
          'PUT',
          data
        )
      },
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.ApiTokens]
        })
      }
    })

  // DELETE /v1/administration/user-account-accesses/api-tokens/{id}
  const useDeleteApiToken = () =>
    useMutation({
      mutationFn: (id: string) =>
        handleApi(
          `/administration/user-account-accesses/api-tokens/${id}`,
          'DELETE'
        ),
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.ApiTokens]
        })
      }
    })

  return {
    useGetApiTokens,
    useGetApiToken,
    useCreateApiToken,
    useUpdateApiToken,
    useDeleteApiToken
  }
}

export default useApiTokens
