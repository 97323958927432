import React from 'react'
import classNames from 'classnames'
import Link from 'next/link'

type Props = {
  item: {
    name: string
    href: string
    icon: any
    current: boolean
  }
}

const SidebarNavigationContextItem: React.FC<Props> = ({ item }) => {
  if (!item?.name) {
    return (
      <li className="flex items-center p-2 gap-x-3">
        <div className="w-5 h-5 skeleton" />
        <div className="h-6 skeleton w-36" />
      </li>
    )
  }
  return (
    <li>
      <Link href={item.href} passHref>
        <a
          className={classNames(
            item.current
              ? 'bg-gray-200 hover:bg-gray-300 text-gray-700 hover:text-gray-800'
              : 'bg-transparent hover:bg-gray-50 text-gray-500 hover:text-gray-800',
            'group flex gap-x-3 py-2 pl-2 pr-3 text-sm leading-6 font-semibold'
          )}
        >
          <item.icon
            className={classNames(
              item.current
                ? 'text-gray-700 hover:text-gray-800'
                : 'text-gray-500 hover:text-gray-800 group-hover:text-gray-800',
              'h-6 w-6 shrink-0'
            )}
            aria-hidden="true"
          />
          {item.name}
        </a>
      </Link>
    </li>
  )
}

export default SidebarNavigationContextItem
