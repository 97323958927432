import {
  createContext,
  useContext,
  useState,
  useCallback,
  useMemo,
  useRef,
  useEffect
} from 'react'
import { DittofeedSdk } from '@dittofeed/sdk-web'
import { trackEvent } from '@phntms/next-gtm'
import Gleap from 'gleap'
import Cookies from 'js-cookie'
import mixpanel from 'mixpanel-browser'
import { useRouter } from 'next/router'
import { toast } from 'react-toastify'
import useLocalStorage from '@/hooks/useLocalStorage'
import api from '@/services/api'
import tToast from '@/translations/toasts'
import {
  AuthMetadata,
  CreateInviteActionRequest,
  LoginRequest,
  RegisterRequest,
  LoggedUserAccountContext,
  Lang,
  InviteType
} from '@/types/global'
import { GtmEvent } from '@/types/gtm.types'
import {
  AuthContext,
  AuthProps,
  InviteProps,
  RecoveryPasswordRequest
} from './Auth.types'
import { TOKEN_KEY } from '@/constants/LocalStorageTokens'
import useAuthApi from '@/hooks/api/auth/useAuthApi/useAuthApi'
import { hashToSha256WithoutSalt } from '@/lib/utils'
import { showToast } from '@/ui/atoms/index'
import { stringify } from '@/utils'

const store = createContext<AuthContext>({} as AuthContext)
const { Provider } = store

function Auth({ children }: AuthProps) {
  const [lang] = useLocalStorage<Lang>('lang', 'PtBr')
  const t = useMemo(() => tToast?.[lang], [lang])
  const [loading, setLoading] = useState(false)
  const [loadingRefresh, setLoadingRefresh] = useState(false)
  const [error, setError] = useState(false)
  const [authMetadata, setAuthMetadata, isLoadingAuthMetadata] =
    useLocalStorage<AuthMetadata>(TOKEN_KEY, {} as AuthMetadata)
  const [userContext, setUserContext, isLoadingUserContext] =
    useLocalStorage<LoggedUserAccountContext>(
      'userContext',
      {} as LoggedUserAccountContext
    )
  const [inviteProps, setInviteProps] = useState<InviteProps>({} as InviteProps)
  const [isSandbox, setIsSandbox, isLoadingIsSandbox] = useLocalStorage(
    'isSandbox',
    false
  )
  const [isLoadingRefreshProfile, setIsLoadingRefreshProfile] = useState(false)
  const refreshTokenPromiseRef = useRef<Promise<AuthMetadata> | null>(null)
  const { push } = useRouter()

  const {
    useRequestAuthMetadata,
    useRequestRefreshToken,
    useGetAuthContextByToken,
    useLoginGoogle,
    useGetListUserAccountsByToken,
    useGetUserAccountContext
  } = useAuthApi()
  const { mutateAsync: mutateAsyncRequestAuthMetadata } =
    useRequestAuthMetadata()
  const {
    mutateAsync: mutateAsyncRequestRefreshToken,
    isLoading: isLoadingRequestRefreshToken
  } = useRequestRefreshToken()
  const { mutateAsync: mutateAsyncGetAuthContextByToken } =
    useGetAuthContextByToken()
  const { mutateAsync: mutateAsyncLoginGoogle } = useLoginGoogle()
  const { mutateAsync: mutateAsyncGetListUserAccountsByToken } =
    useGetListUserAccountsByToken()
  const { mutateAsync: mutateAsyncUserAccountContext } =
    useGetUserAccountContext()

  const isLogged = useMemo(
    () =>
      Boolean(
        userContext?.userAccountInformation?.id && authMetadata.accessToken
      ),
    [authMetadata?.accessToken, userContext?.userAccountInformation?.id]
  )

  const isAdmin = useMemo(() => {
    if (isLoadingUserContext) return null
    return userContext?.userAccountInformation?.role === 'Administrator'
  }, [isLoadingUserContext, userContext?.userAccountInformation?.role])

  const isSandboxEnabled = useMemo(() => {
    if (isLoadingIsSandbox) return null
    return userContext?.userAccountInformation?.isSandboxEnabled
  }, [
    isLoadingIsSandbox,
    userContext?.userAccountInformation?.isSandboxEnabled
  ])

  const getToastT = useCallback(
    (lang: Lang, item: keyof (typeof tToast)[Lang]) => {
      return tToast?.[lang]?.[item as keyof (typeof tToast)[Lang]]
    },
    []
  )

  const trackPreRegisterWithEmail = (email: string, inviteId: string) => {
    trackEvent({
      event: GtmEvent.PreRegisterWithEmail,
      data: {
        email: hashToSha256WithoutSalt(email),
        inviteId: hashToSha256WithoutSalt(inviteId)
      }
    })
  }

  const trackRegisterWithEmail = (inviteId: string, email?: string) => {
    if (!email) return
    trackEvent({
      event: GtmEvent.RegisterWithEmail,
      data: {
        email: hashToSha256WithoutSalt(email),
        inviteId: hashToSha256WithoutSalt(inviteId)
      }
    })
  }

  const trackRegisterWithGoogle = () => {
    trackEvent({
      event: GtmEvent.RegisterWithGoogle
    })
  }

  const getAuthContextByToken = useCallback(
    async (accessToken: string) => {
      try {
        const data = await mutateAsyncGetAuthContextByToken(accessToken)
        return data
      } catch (error) {
        showToast.error(t?.errorRefreshProfile)
        setAuthMetadata({} as AuthMetadata)
        setUserContext({} as LoggedUserAccountContext)
      }
    },
    [
      mutateAsyncGetAuthContextByToken,
      setAuthMetadata,
      setUserContext,
      t?.errorRefreshProfile
    ]
  )

  const getUserContextByAccount = useCallback(
    async (accountId: string) => {
      try {
        const data = await mutateAsyncUserAccountContext(accountId)

        return data
      } catch (error) {
        showToast.error(t?.errorRefreshProfile)
        setAuthMetadata({} as AuthMetadata)
        setUserContext({} as LoggedUserAccountContext)
      }
    },
    [
      mutateAsyncUserAccountContext,
      setAuthMetadata,
      setUserContext,
      t?.errorRefreshProfile
    ]
  )

  const requestAuthMetadata = useCallback(
    async (formData: LoginRequest) => {
      setLoading(true)

      try {
        const dataAuthMetadata = await mutateAsyncRequestAuthMetadata(formData)

        return dataAuthMetadata
      } catch ({ response }: any) {
        setError(true)

        if (
          response?.status === 400 &&
          response?.data?.errors?.[0] ===
            'Unable to generate the credentials with the e-mail and password provided.'
        ) {
          showToast.error(t?.errorInvalidFields)
          return
        }
        if (
          response?.status === 400 &&
          response?.data?.errors?.[0]?.includes(
            "'Password' must be between 8 and 255 characters."
          )
        ) {
          showToast.error(t?.errorPasswordLength)
          return
        }
        showToast.error(t?.errorLoginAction)
        setLoading(false)
      }
    },
    [mutateAsyncRequestAuthMetadata, t]
  )

  const refreshProfile = useCallback(async () => {
    if (isLoadingRefreshProfile) return
    setIsLoadingRefreshProfile(true)
    try {
      const response = await api.get('/auth/user-account-context', {
        headers: {
          Authorization: `Bearer ${authMetadata.accessToken}`,
          ...(userContext?.userAccountInformation?.id && {
            'X-Signater-UserAccountId': userContext?.userAccountInformation?.id
          })
        }
      })

      setUserContext(response.data)
      return response.data
    } catch (error) {
      showToast.error(t?.errorRefreshProfile)
      setAuthMetadata({} as AuthMetadata)
      setUserContext({} as LoggedUserAccountContext)
    } finally {
      setIsLoadingRefreshProfile(false)
    }
  }, [
    authMetadata?.accessToken,
    isLoadingRefreshProfile,
    setAuthMetadata,
    setUserContext,
    t?.errorRefreshProfile,
    userContext?.userAccountInformation?.id
  ])

  const getListUserAccounts = useCallback(
    async (accessToken: string) => {
      try {
        const data = await mutateAsyncGetListUserAccountsByToken(accessToken)

        return data
      } catch (error) {
        setAuthMetadata({} as AuthMetadata)
        setUserContext({} as LoggedUserAccountContext)
      }
    },
    [mutateAsyncGetListUserAccountsByToken, setAuthMetadata, setUserContext]
  )

  const loginAction = useCallback(
    async (formData: LoginRequest) => {
      setError(false)

      try {
        const dataAuthMetadata = await requestAuthMetadata(formData)

        if (dataAuthMetadata) {
          setAuthMetadata({
            ...dataAuthMetadata,
            expiresInDate: new Date(
              new Date(new Date().toUTCString()).getTime() +
                dataAuthMetadata.expiresIn * 1000
            )
          })

          const listUserAccounts = await getListUserAccounts(
            dataAuthMetadata.accessToken
          )

          if (!listUserAccounts?.items) {
            showToast.error(t?.errorDontHaveAccount)
            push('/logout')
            setLoading(false)
            return
          }
          if (listUserAccounts?.items.length === 1) {
            const response = await getAuthContextByToken(
              dataAuthMetadata.accessToken
            )
            if (response?.userAccountInformation?.id) {
              setUserContext(response)
              push('/')
              setLoading(false)
              showToast.success(
                getToastT(
                  response?.userAccountInformation?.language,
                  'successLoginAction'
                )
              )
              return
            }
            return
          }
          if (listUserAccounts?.items.length > 1) {
            push('/switch-account')
            setLoading(false)
            return
          }
        }
      } catch (error) {
        console.log('err', error)
        setAuthMetadata({} as AuthMetadata)
        setUserContext({} as LoggedUserAccountContext)
        showToast.error(t?.errorLoginAction)
      } finally {
        setLoading(false)
      }
    },
    [
      getAuthContextByToken,
      getListUserAccounts,
      getToastT,
      push,
      requestAuthMetadata,
      setAuthMetadata,
      setUserContext,
      t?.errorDontHaveAccount,
      t?.errorLoginAction
    ]
  )

  const logoutAction = useCallback(() => {
    push('/logout')
  }, [push])

  const refreshToken = useCallback(() => {
    if (!refreshTokenPromiseRef.current) {
      refreshTokenPromiseRef.current = new Promise((resolve, reject) => {
        mutateAsyncRequestRefreshToken({
          refreshToken: authMetadata.refreshToken,
          accessToken: authMetadata.accessToken
        })
          .then((response) => {
            if (response) {
              const newAuthMetadata = {
                ...authMetadata,
                accessToken: response.accessToken,
                expiresInDate: new Date(
                  new Date().getTime() + response.expiresIn * 1000
                )
              }
              setAuthMetadata(newAuthMetadata)

              api.defaults.headers.common['Authorization'] =
                `Bearer ${response.accessToken}`

              resolve(newAuthMetadata)
            } else {
              reject(new Error('Failed to refresh token'))
              logoutAction()
            }
          })
          .catch((error) => {
            console.error('Error refreshing token:', error)
            reject(error)
            logoutAction()
          })
          .finally(() => {
            setLoadingRefresh(false)
            refreshTokenPromiseRef.current = null
          })
      })
    }
    return refreshTokenPromiseRef.current
  }, [
    authMetadata,
    logoutAction,
    mutateAsyncRequestRefreshToken,
    setAuthMetadata
  ])

  const refreshTokenWithValidator = useCallback(async () => {
    if (loadingRefresh) {
      return null
    }

    const timeDiff =
      new Date(authMetadata.expiresInDate).getTime() -
      new Date(new Date().toUTCString()).getTime()
    const remainingTimeInMinutes = Math.round(timeDiff / 60000)

    if (remainingTimeInMinutes <= 1 && !isLoadingRequestRefreshToken) {
      return await refreshToken()
    }

    return null
  }, [loadingRefresh, authMetadata, isLoadingRequestRefreshToken, refreshToken])

  const getTrackingCookie = () => {
    const affiliateTrackingId = Cookies.get('TrackingAffiliateId')
    const utmSource = Cookies.get('TrackingUtmSource')
    const utmMedium = Cookies.get('TrackingUtmMedium')
    const utmCampaign = Cookies.get('TrackingUtmCampaign')
    const utmContent = Cookies.get('TrackingUtmContent')
    const utmTerm = Cookies.get('TrackingUtmTerm')
    const referrer = Cookies.get('TrackingReferrer')
    const siteDomain = Cookies.get('TrackingSiteDomain')
    const appDomain = Cookies.get('TrackingAppDomain')
    const generatedAtUtc = Cookies.get('TrackingGeneratedAtUtc')

    return {
      affiliateTrackingId,
      utmSource,
      utmMedium,
      utmCampaign,
      utmContent,
      utmTerm,
      referrer,
      siteDomain,
      appDomain,
      generatedAtUtc
    }
  }

  const createInviteAction = useCallback(
    async (formData: CreateInviteActionRequest) => {
      if (loading) {
        return
      }

      setLoading(true)
      setError(false)
      toast.dismiss()

      const body = {
        ...formData,
        tenantCode: 'BRAZIL',
        language: 'PtBr',
        tracking: getTrackingCookie()
      }

      try {
        const response = await api.get(
          '/auth/is-available/email/' + formData.email
        )
        const { isAvailable } = response.data

        if (!isAvailable) {
          toast.dismiss()
          showToast.error(t?.errorEmailIsNotAvailable)
          setLoading(false)
          return
        }

        const responseInvite = await api.post(`/auth/invite`, body)
        const { inviteId = '' } = responseInvite.data

        trackPreRegisterWithEmail(formData.email, inviteId)

        setInviteProps({ email: formData.email, inviteId: inviteId })

        // Dittofeed
        DittofeedSdk.identify({
          userId: formData.email,
          traits: {
            email: formData.email,
            name: formData.name
          }
        })

        push({
          pathname: '/register',
          query: { inviteId }
        })
      } catch (error) {
        showToast.error(t?.errorInRegister)
        setTimeout(() => {
          push('/register')
        }, 3000)
      } finally {
        setLoading(false)
      }
    },
    [loading, push, t?.errorEmailIsNotAvailable, t?.errorInRegister]
  )

  const registerAction = useCallback(
    async (formData: RegisterRequest, email?: string, type?: InviteType) => {
      if (loading) {
        return
      }

      setLoading(true)
      setError(false)
      toast.dismiss()

      const body = {
        code: formData.code,
        password: formData.password,
        recaptchaToken: formData.recaptchaToken,
        tracking: getTrackingCookie()
      }

      try {
        const response = await api.post(
          `/auth/register/${formData.inviteId}`,
          body
        )
        const { data, status } = response

        setAuthMetadata({
          ...data,
          expiresInDate: new Date(
            new Date(new Date().toUTCString()).getTime() + data.expiresIn * 1000
          )
        })

        if (type === 'External') {
          trackRegisterWithEmail(formData.inviteId, email)
        }

        if (status === 202) {
          showToast.success(t?.successRegister)
          push('/login')
          return
        }

        const listUserAccounts = await getListUserAccounts(data.accessToken)

        if (!listUserAccounts?.items) {
          showToast.error(t?.errorDontHaveAccount)
          push('/logout')
          setLoading(false)
          return
        }
        if (listUserAccounts?.items.length === 1) {
          const response = await getAuthContextByToken(data.accessToken)
          if (response?.userAccountInformation?.id) {
            setUserContext(response)
            push('/')
            setLoading(false)
            showToast.success(
              getToastT(
                response?.userAccountInformation?.language,
                'successLoginAction'
              )
            )
            return
          }
          return
        }
        if (listUserAccounts?.items.length > 1) {
          push('/switch-account')
          setLoading(false)
          return
        }
      } catch ({ response }: any) {
        if (
          response?.status === 500 &&
          response?.data?.error &&
          response?.data?.error?.includes(
            'Another object with the same value for property userPrincipalName already exists.'
          )
        ) {
          showToast.error(t?.errorEmailIsNotAvailable)
          push('/register')
          return
        }
        if (
          response?.status === 400 &&
          response?.data?.errors?.[0].metadata.message.includes(
            'Code is invalid.'
          )
        ) {
          showToast.error(t?.errorCodeIsNotValid)
          return
        }
      } finally {
        setLoading(false)
      }
    },
    [
      getAuthContextByToken,
      loading,
      push,
      setAuthMetadata,
      t?.errorCodeIsNotValid,
      t?.errorEmailIsNotAvailable,
      t?.successRegister
    ]
  )

  const sendCodeRecoveryPassword = useCallback(
    async (email: string, recaptchaToken: string) => {
      if (loading) {
        return
      }

      setLoading(true)

      const body = {
        email,
        recaptchaToken
      }

      const response = await api
        .post(`/auth/password-recovery`, body)
        .then(() => {
          return { success: true }
        })
        .catch(() => {
          showToast.error(t?.errorInSendCode)
          return { success: false }
        })
        .finally(() => {
          setLoading(false)
        })

      return response
    },
    [loading, t]
  )

  const recoveryPasswordAction = useCallback(
    async (formData: RecoveryPasswordRequest) => {
      if (loading) {
        return
      }

      setLoading(true)

      const body = {
        code: formData.code,
        password: formData.password,
        recaptchaToken: formData.recaptchaToken
      }

      try {
        const response = await api.post(
          `/auth/password-recovery/${formData.email}/reset-password`,
          body
        )
        showToast.success(t?.successInRecoveryPassword)

        if (response?.status === 202 || !response?.data?.accessToken) {
          push('/login')
          return
        }

        if (response?.data?.accessToken) {
          const dataUser = await getAuthContextByToken(
            response.data.accessToken
          )
          if (dataUser?.userAccountInformation?.id) {
            setAuthMetadata({
              ...response.data,
              expiresInDate: new Date(
                new Date(new Date().toUTCString()).getTime() +
                  response.data.expiresIn * 1000
              )
            })
            setTimeout(() => {
              push('/login')
            }, 3000)
          }
        }

        return response.data
      } catch (error) {
        showToast.error(t?.errorInRecoveryPassword)
        return error
      } finally {
        setLoading(false)
      }
    },
    [
      getAuthContextByToken,
      loading,
      push,
      setAuthMetadata,
      t?.errorInRecoveryPassword,
      t?.successInRecoveryPassword
    ]
  )

  useEffect(() => {
    if (!userContext?.userAccountInformation) return

    const tenant = userContext.tenantInformation
    const account = userContext.accountInformation
    const userAccount = userContext.userAccountInformation
    const hasActiveTrial = userContext.hasActiveTrial
    const hasActiveSubscription = userContext.hasActiveSubscription
    const subscription = userContext.subscriptionInformation

    // Gleap
    Gleap.identify(userAccount.id, {
      name: userAccount.name,
      email: userAccount.email,
      plan: subscription?.planCode,
      companyId: account.id,
      companyName: account.name,
      customData: {
        tenantId: tenant.id,
        tenantCode: tenant.code,
        accountId: account.id,
        accountName: account.name,
        accountOnboarded: account.onboarded ? 'Yes' : 'No',
        userAccountId: userAccount.id,
        userAccountEmail: userAccount.email,
        userAccountAvatar: userAccount.avatar,
        userAccountRole: userAccount.role,
        userAccountHasSandboxEnabled: userAccount.isSandboxEnabled
          ? 'Yes'
          : 'No',
        hasActiveTrial: hasActiveTrial ? 'Yes' : 'No',
        hasActiveSubscription: hasActiveSubscription ? 'Yes' : 'No'
      }
    })
    const gleapLanguage = userAccount.language === 'PtBr' ? 'pt-BR' : 'en-US'
    Gleap.setLanguage(gleapLanguage)

    // Mixpanel
    mixpanel.identify(userAccount.id)
    mixpanel.people.set({
      $name: userAccount.name,
      $email: userAccount.email,
      plan: subscription?.planCode,
      tenantId: tenant.id,
      tenantCode: tenant.code,
      accountId: account.id,
      accountName: account.name,
      accountOnboarded: account.onboarded ? 'Yes' : 'No',
      userAccountId: userAccount.id,
      userAccountEmail: userAccount.email,
      userAccountAvatar: userAccount.avatar,
      userAccountRole: userAccount.role,
      userAccountHasSandboxEnabled: userAccount.isSandboxEnabled ? 'Yes' : 'No',
      hasActiveTrial: hasActiveTrial ? 'Yes' : 'No',
      hasActiveSubscription: hasActiveSubscription ? 'Yes' : 'No'
    })

    // Dittofeed
    // DittofeedSdk.identify({
    //   userId: userAccount.email,
    //   traits: {
    //     email: userAccount.email,
    //     name: userAccount.name,
    //     role: userAccount.role,
    //     tenantId: tenant.id,
    //     tenantCode: tenant.code,
    //     accountId: account.id,
    //     accountName: account.name
    //   }
    // })
  }, [userContext])

  const urlFederatedRegister = useMemo(() => {
    const googleStringifiedParams = stringify({
      client_id: process.env.NEXT_PUBLIC_AUTH_REGISTER_GOOGLE_CLIENT_ID,
      redirect_uri: process.env.NEXT_PUBLIC_AUTH_REGISTER_GOOGLE_REDIRECT_URI,
      scope: [
        'openid',
        'profile',
        'https://www.googleapis.com/auth/userinfo.email',
        'https://www.googleapis.com/auth/userinfo.profile'
      ].join(' '),
      response_type: 'code',
      access_type: 'offline',
      prompt: 'select_account'
    })

    const googleRegisterUrl = `https://accounts.google.com/o/oauth2/v2/auth?${googleStringifiedParams}`

    return googleRegisterUrl
  }, [])

  const urlFederatedLogin = useMemo(() => {
    const baseUrl = process.env.NEXT_PUBLIC_AUTH_LOGIN_AZURE_BASE_URL
    const clientId = process.env.NEXT_PUBLIC_AUTH_LOGIN_AZURE_CLIENT_ID
    const redirectUri = process.env.NEXT_PUBLIC_AUTH_LOGIN_AZURE_REDIRECT_URI
    const scope = process.env.NEXT_PUBLIC_AUTH_LOGIN_AZURE_SCOPE

    const googleLoginUrl = `${baseUrl}?p=B2C_1_SIGNIN&client_id=${clientId}&domain_hint=google.com&redirect_uri=${redirectUri}&scope=openid ${scope}&response_type=code&prompt=login`

    return googleLoginUrl
  }, [])

  const googleRegisterAction = useCallback(
    async (code: string, recaptchaToken: string) => {
      if (!code) {
        push('/login')
        return
      }

      setLoading(true)

      try {
        const redirectUri =
          process.env.NEXT_PUBLIC_AUTH_REGISTER_GOOGLE_REDIRECT_URI || ''

        const response = await api.post('/auth/register/google', {
          code,
          recaptchaToken,
          redirectUri,
          tracking: getTrackingCookie()
        })
        const data = response.data
        const status = response.status

        if (status === 201 || status === 202) {
          trackRegisterWithGoogle()
        }

        switch (status) {
          case 201:
            if (data.accessToken) {
              setAuthMetadata({
                ...data,
                expiresInDate: new Date(
                  new Date(new Date().toUTCString()).getTime() +
                    data.expiresIn * 1000
                )
              })
              const listUserAccounts = await getListUserAccounts(
                data.accessToken
              )

              if (!listUserAccounts?.items) {
                showToast.error(t?.errorDontHaveAccount)
                push('/logout')
                setLoading(false)
                return
              }
              if (listUserAccounts?.items.length === 1) {
                const response = await getAuthContextByToken(data.accessToken)
                if (response?.userAccountInformation?.id) {
                  setUserContext(response)
                  push('/')
                  setLoading(false)
                  showToast.success(
                    getToastT(
                      response?.userAccountInformation?.language,
                      'successLoginAction'
                    )
                  )
                  return
                }
                return
              }
              if (listUserAccounts?.items.length > 1) {
                push('/switch-account')
                setLoading(false)
                return
              }
            }
            break
          case 202:
            showToast.success(t?.successInRecoveryPassword)
            push('/login?clickGoogleLogin=true')
            break
          case 200:
            if (data.hasProvider) {
              push('/login?clickGoogleLogin=true')
            } else {
              push(`/login?email=${data.toEmail}`)
              showToast.error(t?.errorRegisterGoogle)
            }
            break
          default:
            showToast.error(t?.errorRegisterAction)
            push('/login')
            break
        }
      } catch (error) {
        console.error(error)
        showToast.error(t?.errorRegisterAction)
        push('/')
      } finally {
        setLoading(false)
      }
    },
    [
      getAuthContextByToken,
      push,
      setAuthMetadata,
      t?.errorRegisterAction,
      t?.errorRegisterGoogle,
      t?.successInRecoveryPassword
    ]
  )

  const googleLoginAction = useCallback(
    async (code: string) => {
      if (!code) {
        push('/login')
        return
      }

      setLoading(true)

      try {
        const dataAuthMetadata = await mutateAsyncLoginGoogle({ code })

        if (dataAuthMetadata?.accessToken) {
          setAuthMetadata({
            ...dataAuthMetadata,
            expiresInDate: new Date(
              new Date(new Date().toUTCString()).getTime() +
                dataAuthMetadata.expiresIn * 1000
            )
          })

          const listUserAccounts = await getListUserAccounts(
            dataAuthMetadata.accessToken
          )

          if (!listUserAccounts?.items) {
            showToast.error(t?.errorDontHaveAccount)
            push('/logout')
            setLoading(false)
            return
          }
          if (listUserAccounts?.items.length === 1) {
            const response = await getAuthContextByToken(
              dataAuthMetadata.accessToken
            )
            if (response?.userAccountInformation?.id) {
              setUserContext(response)
              push('/')
              showToast.success(
                getToastT(
                  response?.userAccountInformation?.language,
                  'successLoginAction'
                )
              )
              setLoading(false)
              return
            }
            return
          }
          if (listUserAccounts?.items.length > 1) {
            push('/switch-account')
            setLoading(false)
            return
          }
        }
      } catch (error) {
        showToast.error(t?.errorLoginAction)
        push('/login')
      } finally {
        setLoading(false)
      }
    },
    [
      getAuthContextByToken,
      getListUserAccounts,
      getToastT,
      mutateAsyncLoginGoogle,
      push,
      setAuthMetadata,
      setUserContext,
      t?.errorDontHaveAccount,
      t?.errorLoginAction
    ]
  )

  return (
    <Provider
      value={{
        userContext,
        authMetadata,
        isLogged,
        isAdmin,
        setLoading,
        loading,
        error,
        loginAction,
        logoutAction,
        refreshProfile,
        refreshTokenWithValidator,
        createInviteAction,
        registerAction,
        inviteProps,
        sendCodeRecoveryPassword,
        recoveryPasswordAction,
        isSandbox,
        setIsSandbox,
        isSandboxEnabled,
        isLoadingRefreshProfile,
        isLoadingAuthMetadata,
        urlFederatedRegister,
        urlFederatedLogin,
        googleRegisterAction,
        googleLoginAction,
        getUserContextByAccount,
        setUserContext
      }}
    >
      {children}
    </Provider>
  )
}

const useAuth = () => useContext(store)

export { Auth, useAuth }
