import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { MagnifyingGlassIcon, PlusCircledIcon } from '@radix-ui/react-icons'
import Image from 'next/image'
import useTranslation from '@/hooks/useTranslation'
import Avatar from '../Avatar'
import Checkbox from '../Checkbox'
import { Label, Popover, PopoverContent, PopoverTrigger } from '../shadcn'
import Typography from '../Typography'
import { useVaultApi } from '@/hooks/api/ecm/useVaultApi'
import { cn } from '@/lib/utils'
import { AvatarGroup } from '@/ui/molecules'

export type OptionType = {
  value: string
  label: string
  avatar?: string | null
  disabled?: boolean
  isSelected?: boolean
  isCurrentUser?: boolean
  isUserActive?: boolean
}

export type CurrentVaultId = string | null

export type PropsType = {
  name: string
  onSelected: (option: string[] | null) => void
  size?: 'sm' | 'md'
  showSkeleton?: boolean
  timeoutSkeleton?: number
  fullWidth?: boolean
  label?: string
  className?: string
  value: string[] | null
  placeholder?: string
  id?: string
  error?: string
  required?: boolean
  disabled?: boolean
}

const SelectUsers: React.FC<PropsType> = ({
  size = 'md',
  showSkeleton,
  className,
  onSelected,
  value,
  name,
  error,
  disabled,
  ...props
}) => {
  const { t } = useTranslation('selectUsers')
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isOpenPopover, setIsOpenPopover] = useState(false)
  const [options, setOptions] = useState<OptionType[]>([])
  const [valueSearch, setValueSearch] = useState<string | null>(null)
  const { useVaultMembers } = useVaultApi()
  const {
    data: dataMembers,
    isFetched: isFetchedMembers,
    isLoading: isLoadingMembers,
    isError: isErrorMembers
  } = useVaultMembers()

  useEffect(() => {
    if (
      dataMembers?.items &&
      dataMembers?.items.length > 0 &&
      isFetchedMembers
    ) {
      const optionsMembers = dataMembers.items.map((user) => ({
        value: user.userAccountId,
        label: user.userAccountName,
        avatar: user.userAccountAvatar,
        isSelected: value?.length
          ? value?.includes(user.userAccountId)
          : user?.isCurrentUserAccount,
        isCurrentUser: user.isCurrentUserAccount,
        isUserActive: user.isUserAccountActive
      }))
      setOptions(optionsMembers)

      setIsLoading(false)
    }
    if (isErrorMembers) {
      setIsLoading(false)
    }
  }, [dataMembers, dataMembers?.items, isErrorMembers, isFetchedMembers, value])

  const handleCheckboxChange = useCallback(
    (option: OptionType) => {
      const newSelectedOptions = options.map((item) => {
        if (item.value === option.value) {
          return { ...item, isSelected: !item.isSelected }
        }
        return item
      })
      const selected = newSelectedOptions
        .filter((option) => option.isSelected)
        .map((option) => option.value)
      onSelected(selected)
      setOptions(newSelectedOptions)
    },
    [onSelected, options]
  )

  const isEmpty = useMemo(
    () => options?.length === 0 && !isLoadingMembers,
    [options?.length, isLoadingMembers]
  )

  const avatarUsers = useMemo(() => {
    return options
      ?.filter((option) => option.isSelected)
      ?.map((user) => ({
        name: user.label,
        imgSrc: user.avatar
      }))
  }, [options])

  const activeUsers = useMemo(
    () => options.filter((option) => option.isUserActive),
    [options]
  )

  const inactiveUsers = useMemo(
    () => options.filter((option) => !option.isUserActive),
    [options]
  )

  if (showSkeleton || isLoading) {
    const label = {
      sm: 'w-16 h-3',
      md: 'w-24 h-4'
    }[size || 'md']
    const select = {
      sm: 'w-16 h-8',
      md: 'w-full min-w-44 h-10'
    }[size || 'md']

    return (
      <div className={cn('flex flex-col w-full gap-1', className)}>
        <div className={cn('skeleton', label)} />
        <div className={cn('skeleton', select)} />
      </div>
    )
  }

  return (
    <div
      className={cn(
        'relative flex flex-col',
        props.label && 'gap-1',
        className
      )}
    >
      {props.label && <Label htmlFor={props.id}>{props.label}</Label>}
      <div className="relative">
        <Popover
          onOpenChange={(open) => {
            if (disabled) return
            setIsOpenPopover(open)
          }}
          open={isOpenPopover}
        >
          <PopoverTrigger asChild>
            <div
              className={cn(
                'flex items-center gap-2 bg-accent-100 text-accent-900 dark:bg-accent-500 ring-0 border border-gray-300 w-full text-sm px-[13px] h-10 rounded-md shadow-sm font-normal leading-tight outline-none',
                error ? 'border-error-500' : '',
                disabled
                  ? 'cursor-not-allowed opacity-50'
                  : 'cursor-pointer hover:border-primary-200 focus:border-primary-700 focus:ring-primary-700'
              )}
            >
              <div className="flex justify-between w-full">
                <div className="flex items-center w-full gap-2 text-ellipsis">
                  <PlusCircledIcon className="w-4 h-4" />
                  <Typography
                    variant="text-sm-regular"
                    className="flex items-center w-full truncate font-body"
                  >
                    {t?.selectUsers?.title}
                    {props.required && <span>*</span>}
                  </Typography>
                </div>
                {!!avatarUsers?.length && (
                  <AvatarGroup
                    users={avatarUsers}
                    max={5}
                    size="sm"
                    type="usersGroup"
                    showDetails={false}
                  />
                )}
              </div>
            </div>
          </PopoverTrigger>
          <PopoverContent
            className="p-0 z-60 w-[300px] lg:w-[433px] md:w-[333px] xl:w-[518px]"
            align="start"
          >
            <div className="flex items-center h-10 border-b border-gray-200">
              <MagnifyingGlassIcon
                className="absolute w-5 h-5 text-gray-400 pointer-events-none left-4"
                aria-hidden="true"
              />
              <input
                type="text"
                className="w-full h-10 pl-12 text-sm border-t-0 border-b border-l-0 border-r-0 rounded-t-sm outline-none border-b-gray-200 focus:ring-0 ring-0 hover:border-b-gray-200 focus:border-b-gray-200 placeholder:text-gray-400 dark:bg-accent-500"
                value={valueSearch ?? ''}
                onChange={(e) => setValueSearch(e.target.value)}
                placeholder={props.placeholder || ''}
              />
            </div>
            <div className="overflow-y-auto max-h-60">
              <ul className="flex flex-col gap-[2px] p-2">
                {activeUsers.length > 0 && (
                  <>
                    <Typography
                      variant="text-sm-medium"
                      className="pl-2 mt-1 mb-2"
                    >
                      {t?.selectUsers?.activeUsers}
                    </Typography>
                    {activeUsers.map((item, index) => (
                      <label
                        key={index}
                        htmlFor={item.value.toString()}
                        className="flex items-center w-full px-2 py-1 cursor-pointer"
                      >
                        <Checkbox
                          name={item.value}
                          id={item.value.toString()}
                          variant="secondary"
                          checked={item.isSelected}
                          onChange={() => handleCheckboxChange(item)}
                        >
                          <div className="flex items-center gap-3">
                            {(item.avatar || item.label) && (
                              <Avatar
                                name={item.label}
                                imgSrc={
                                  typeof item.avatar !== 'boolean'
                                    ? item.avatar
                                    : undefined
                                }
                                size="sm"
                              />
                            )}
                            {item.label}
                          </div>
                        </Checkbox>
                      </label>
                    ))}
                  </>
                )}
                {inactiveUsers.length > 0 && (
                  <>
                    <Typography
                      variant="text-sm-medium"
                      className="pl-2 mt-4 mb-2"
                    >
                      {t?.selectUsers?.inactiveUsers}
                    </Typography>
                    {inactiveUsers.map((item, index) => (
                      <label
                        key={index}
                        htmlFor={item.value.toString()}
                        className="flex items-center w-full px-2 py-1 cursor-pointer"
                      >
                        <Checkbox
                          name={item.value}
                          id={item.value.toString()}
                          variant="secondary"
                          checked={item.isSelected}
                          onChange={() => handleCheckboxChange(item)}
                        >
                          <div className="flex items-center gap-3">
                            {(item.avatar || item.label) && (
                              <Avatar
                                name={item.label}
                                imgSrc={
                                  typeof item.avatar !== 'boolean'
                                    ? item.avatar
                                    : undefined
                                }
                                size="sm"
                              />
                            )}
                            {item.label}
                          </div>
                        </Checkbox>
                      </label>
                    ))}
                  </>
                )}
              </ul>
              {isLoadingMembers && (
                <div className="flex flex-col gap-[2px] p-2">
                  {Array.from({ length: 10 }).map((_, index) => (
                    <div className="flex items-center gap-1 p-2" key={index}>
                      <div className="w-4 h-4 skeleton" />
                      <div className="h-4 w-28 skeleton" />
                    </div>
                  ))}
                </div>
              )}
              {isEmpty && (
                <div className="flex flex-col items-center justify-center h-32 gap-1 p-2">
                  <div className="relative w-full h-full max-w-32">
                    <Image
                      src="/assets/icons/empty-state.svg"
                      alt="Folder Dashboard"
                      layout="fill"
                    />
                  </div>
                  <Typography variant="text-sm-regular" className="text-center">
                    {t?.selectUsers?.noOptions}
                  </Typography>
                </div>
              )}
            </div>
          </PopoverContent>
        </Popover>
      </div>
    </div>
  )
}

export default SelectUsers
