import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import useApi from '@/hooks/useApi'
import { useAuth } from '@/providers/Auth'
import {
  GetAccountSustainabilityCertificatesResponse,
  UpdateAccountSustainabilityCertificatesRequest
} from './useAccountSustainabilityCertificatesApi.types'
import { QueryKeys } from '@/hooks/useApi/useApi.types'

const useAccountSustainabilityCertificatesApi = () => {
  const { handleApi, queryConfig } = useApi()
  const queryClient = useQueryClient()
  const { isAdmin, isLogged } = useAuth()

  const useGetAccountSustainabilityCertificates = () => {
    return useQuery({
      queryKey: [QueryKeys.AccountSustainabilityCertificates],
      queryFn: () =>
        handleApi<void, GetAccountSustainabilityCertificatesResponse>(
          '/report/account-sustainability-certificates',
          'GET'
        ),
      ...queryConfig,
      enabled: Boolean(isLogged && isAdmin)
    })
  }

  const useUpdateAccountSustainabilityCertificates = () =>
    useMutation({
      mutationFn: (data: UpdateAccountSustainabilityCertificatesRequest) =>
        handleApi<UpdateAccountSustainabilityCertificatesRequest, void>(
          '/report/account-sustainability-certificates',
          'PUT',
          data
        ),
      ...queryConfig,
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.AccountSustainabilityCertificates]
        })
      }
    })

  return {
    useGetAccountSustainabilityCertificates,
    useUpdateAccountSustainabilityCertificates
  }
}

export default useAccountSustainabilityCertificatesApi
