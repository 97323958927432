const content = {
  labelClose: 'Close',
  errorRefreshProfile: 'Error fetching profile information',
  errorInvalidFields: 'Invalid fields',
  errorEmailIsNotAvailable: 'Email already registered',
  errorInRegister: 'Error creating account',
  errorCodeIsNotValid: 'Invalid code',
  successInRecoveryPassword: 'Password  recovered',
  errorInRecoveryPassword: 'Error recovering password',
  errorInSendCode: 'Error sending code',
  errorPasswordLength:
    'Password must be at least 8 characters and a maximum of 255 characters',
  successLoginAction: 'Login successful',
  errorLoginAction:
    'Error logging in. Please check your credentials and try again.',
  successRegister: 'Account created ',
  errorRegisterAction: 'Error creating account',
  errorRegisterGoogle:
    'You do not have an account linked with Google. Please log in with your username and password.',
  errorDontHaveAccount: 'You do not belong to any account'
}

export default content
