import React, { Fragment, useCallback, useMemo, useState } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import {
  ArrowPathIcon,
  ArrowRightOnRectangleIcon,
  InformationCircleIcon,
  MagnifyingGlassIcon,
  UserIcon
} from '@heroicons/react/24/outline'
import Link from 'next/link'
import { useRouter } from 'next/router'
import useTranslation from '@/hooks/useTranslation'
import { classNames } from '@/utils/index'
import { useAuth } from '@/providers/Auth'
import { useReviewEnvelope } from '@/providers/ReviewEnvelope'
import { AccountType } from '@/types/constants'
import CommandPaletteSearchBar from '@/organisms/CommandPaletteSearchBar'
import { Avatar, SelectLang, Toggle } from '@/atoms/index'
import SelectTheme from '@/atoms/SelectTheme'
import ToggleChat from '@/atoms/ToggleChat'
import useAuthApi from '@/hooks/api/auth/useAuthApi/useAuthApi'
import { Section } from '@/providers/ReviewEnvelope/ReviewEnvelope.types'
import { Button } from '@/ui/atoms/shadcn'
import {
  ButtonWithTooltip,
  IconWithTooltip,
  ModalSwitchAccount,
  Notifications
} from '@/ui/molecules'

const HeaderOptionGroup: React.FC = () => {
  const { t } = useTranslation(['headerOptionGroup', 'header'], true)
  const [openCommandPaletteSearchBar, setOpenCommandPaletteSearchBar] =
    useState(false)
  const [openModalSwitchAccount, setOpenModalSwitchAccount] = useState(false)

  const {
    userContext,
    logoutAction,
    isSandboxEnabled,
    isSandbox,
    setIsSandbox,
    refreshProfile,
    isLoadingRefreshProfile,
    isLogged
  } = useAuth()
  const { reload, pathname } = useRouter()
  const { currentSection } = useReviewEnvelope()
  const { useGetListUserAccounts } = useAuthApi()
  const {
    data: dataListUserAccounts,
    isLoading: isLoadingListUserAccounts,
    isFetched: isFetchedListUserAccounts
  } = useGetListUserAccounts()

  const hasMultiAccounts = useMemo(() => {
    return (
      !isLoadingListUserAccounts &&
      isFetchedListUserAccounts &&
      dataListUserAccounts?.items &&
      dataListUserAccounts?.items?.length > 1
    )
  }, [
    isLoadingListUserAccounts,
    isFetchedListUserAccounts,
    dataListUserAccounts?.items
  ])

  const userNavigation = useMemo(() => {
    return [
      {
        id: 'MODE_SANDBOX',
        name: t?.avatarNavigation?.modeSandbox,
        href: '#',
        toggleSandbox: true,
        hidden:
          !isSandboxEnabled ||
          pathname === '/envelope' ||
          pathname === '/envelope/[id]/edit' ||
          pathname === '/envelope/[id]/view' ||
          pathname === '/view/[id]' ||
          pathname === '/review/[id]',
        tooltip: t?.avatarNavigation?.tooltipModeSandbox
      },
      {
        id: 'MY_PROFILE',
        name: t?.avatarNavigation?.myProfile,
        href: '/profile',
        icon: UserIcon
      },
      {
        id: 'SWITCH_ACCOUNT',
        name: t?.avatarNavigation?.switchAccount,
        onClick: () => setOpenModalSwitchAccount(true),
        icon: ArrowPathIcon,
        hidden: !hasMultiAccounts
      },
      {
        id: 'LOGOUT',
        name: t?.avatarNavigation?.logout,
        href: '#',
        icon: ArrowRightOnRectangleIcon,
        onClick: () => logoutAction()
      }
    ]
  }, [
    t?.avatarNavigation?.modeSandbox,
    t?.avatarNavigation?.tooltipModeSandbox,
    t?.avatarNavigation?.myProfile,
    t?.avatarNavigation?.switchAccount,
    t?.avatarNavigation?.logout,
    isSandboxEnabled,
    pathname,
    hasMultiAccounts,
    logoutAction
  ])

  const handleChangeSandBoxMode = useCallback(
    async (value) => {
      if (isSandboxEnabled) {
        setIsSandbox(value)
        const data = await refreshProfile()
        if (!data) {
          setIsSandbox(!value)
          return
        }
        reload()
      }
    },
    [isSandboxEnabled, refreshProfile, reload, setIsSandbox]
  )

  const refUrl = useMemo(() => {
    if (pathname === '/review/[id]' && currentSection) {
      if (currentSection === Section.FeedbackEditing) {
        return 'review-editing'
      }
      if (currentSection === Section.FeedbackFailed) {
        return 'review-failed'
      }
      if (currentSection === Section.FeedbackRejected) {
        return 'review-rejected'
      }
      if (currentSection === Section.FeedbackSigned) {
        return 'review-signed'
      }
      if (currentSection === Section.SignDocument) {
        return 'review-sign'
      }
      if (currentSection === Section.ValidationMFA) {
        return 'review-mfa'
      }
    }
    return
  }, [currentSection, pathname])

  const typeInformation = useMemo(() => {
    if (userContext?.accountInformation?.type === AccountType.Company) {
      return userContext?.accountInformation?.name
    }
    if (userContext?.accountInformation?.type === AccountType.Personal) {
      return t?.avatarNavigation?.typePersonal
    }
    return ''
  }, [
    t?.avatarNavigation?.typePersonal,
    userContext?.accountInformation?.name,
    userContext?.accountInformation?.type
  ])

  if (!isLogged) {
    return (
      <div className="flex items-center gap-x-3">
        <ToggleChat />
        <SelectTheme />
        <SelectLang />
        <Link href={`/register${refUrl && `?ref=${refUrl}`}`}>
          <Button>{t?.buttonCreateAccount}</Button>
        </Link>
      </div>
    )
  }

  return (
    <>
      <div className="flex items-center justify-end flex-1 gap-3">
        <ButtonWithTooltip
          type="button"
          variant="unstyled"
          className="text-gray-400"
          onClick={() => setOpenCommandPaletteSearchBar(true)}
          tooltipText={t?.options?.search}
        >
          <span className="sr-only">{t?.options?.search}</span>
          <MagnifyingGlassIcon className="w-5 h-5" aria-hidden="true" />
        </ButtonWithTooltip>
        <Notifications />
        <ToggleChat />
        <SelectTheme />
        <SelectLang />
        <div className="flex items-center justify-center w-full h-16 border-l border-gray-200 max-w-20 dark:border-gray-900/10">
          <Menu as="div" className="relative">
            <Menu.Button className="-m-1.5 flex items-center p-2">
              <>
                <span className="sr-only">{t?.options?.openUserMenu}</span>
                <Avatar
                  name={userContext?.userAccountInformation?.name}
                  imgSrc={userContext?.userAccountInformation?.avatar as string}
                />
                <span className="flex items-center">
                  <ChevronDownIcon
                    className="w-5 h-5 ml-2 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </>
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-10 mt-2.5 w-48 origin-top-right rounded-md bg-white dark:bg-black dark:text-black py-2 shadow-lg ring-1 ring-gray-200 focus:outline-none">
                <Menu.Item key="user-account-info">
                  <div className="flex flex-col flex-wrap items-start gap-1 p-3">
                    <span
                      className="text-sm font-medium leading-4 text-gray-900"
                      aria-hidden="true"
                    >
                      {userContext?.userAccountInformation?.name}
                    </span>
                    <span
                      className="text-xs font-normal leading-3 text-gray-500"
                      aria-hidden="true"
                    >
                      {typeInformation}
                    </span>
                  </div>
                </Menu.Item>
                {userNavigation?.map((item) => {
                  if (item.hidden) {
                    return null
                  }
                  if (item.id === 'LOGOUT' || item.id === 'SWITCH_ACCOUNT') {
                    return (
                      <Menu.Item key={item.id}>
                        {({ active }) => (
                          <button
                            type="button"
                            className={classNames(
                              active ? 'bg-gray-100' : '',
                              'flex justify-between items-center px-3 py-1 w-full '
                            )}
                            onClick={item.onClick}
                          >
                            <p className="text-sm leading-6 text-gray-700">
                              {item.name}
                            </p>
                            {item.icon && (
                              <item.icon
                                className="w-4 h-4 text-gray-700"
                                aria-hidden="true"
                              />
                            )}
                          </button>
                        )}
                      </Menu.Item>
                    )
                  }
                  if (item.id === 'MODE_SANDBOX') {
                    return (
                      <Menu.Item key={item.id}>
                        {({ active }) => {
                          return (
                            <div
                              className={classNames(
                                active ? 'bg-gray-100' : '',
                                'flex justify-between items-center px-3 py-1 w-full cursor-pointer'
                              )}
                            >
                              <div className="flex items-center gap-1">
                                <p
                                  className={`text-sm leading-6 text-gray-700 ${
                                    !isSandboxEnabled
                                      ? 'opacity-50 cursor-not-allowed'
                                      : ''
                                  }`}
                                >
                                  {item.name}
                                </p>
                                <IconWithTooltip
                                  icon={
                                    <InformationCircleIcon className="w-3 h-3 text-gray-700" />
                                  }
                                  tooltipText={item?.tooltip}
                                />
                              </div>
                              {item.toggleSandbox && (
                                <Toggle
                                  name="sandboxMode"
                                  checked={isSandbox || false}
                                  onChange={handleChangeSandBoxMode}
                                  disabled={
                                    !isSandboxEnabled || isLoadingRefreshProfile
                                  }
                                />
                              )}
                            </div>
                          )
                        }}
                      </Menu.Item>
                    )
                  }
                  return (
                    <Menu.Item key={item.id}>
                      {({ active }) => (
                        <Link href={item.href as string} passHref>
                          <a
                            className={classNames(
                              active ? 'bg-gray-100' : '',
                              'flex justify-between items-center px-3 py-1 w-full hover:bg-gray-100'
                            )}
                          >
                            <p className="text-sm leading-6 text-gray-700">
                              {item.name}
                            </p>
                            {item.icon && (
                              <item.icon
                                className="w-4 h-4 text-gray-700"
                                aria-hidden="true"
                              />
                            )}
                          </a>
                        </Link>
                      )}
                    </Menu.Item>
                  )
                })}
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>
      <CommandPaletteSearchBar
        open={openCommandPaletteSearchBar}
        setOpen={setOpenCommandPaletteSearchBar}
      />
      <ModalSwitchAccount
        isOpen={openModalSwitchAccount}
        onClose={() => setOpenModalSwitchAccount(false)}
      />
    </>
  )
}

export default HeaderOptionGroup
