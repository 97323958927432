import React, { useEffect } from 'react'
import { DittofeedSdk } from '@dittofeed/sdk-web'
import { getWebInstrumentations, initializeFaro } from '@grafana/faro-react'
import { TrackingHeadScript } from '@phntms/next-gtm'
import * as Sentry from '@sentry/nextjs'
import {
  QueryClientProvider,
  QueryClient,
  MutationCache,
  QueryCache
} from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import Gleap from 'gleap'
import Cookies from 'js-cookie'
import mixpanel from 'mixpanel-browser'
import { AppProps } from 'next/app'
import Head from 'next/head'
import '../styles/global.css'
import { useRouter } from 'next/router'
import { DefaultSeo } from 'next-seo'
import { ThemeProvider } from 'next-themes'
import { GoogleAnalytics } from 'nextjs-google-analytics'
import { lighten, darken, adjustHue } from 'polished'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { clarity } from 'react-microsoft-clarity'
import { useFacebookPixel } from '@/hooks/useFacebookPixel'
import { useViewport } from '@/hooks/useViewport'
import { Providers } from '@/providers/Providers'
import 'react-phone-number-input/style.css'
import SEO from '../../next-seo.config'
import { ErrorBoundary } from '@/ui/atoms'
import 'react-toastify/dist/ReactToastify.css'

const isHexValid = (hex: string): boolean => {
  return /^#[0-9A-F]{6}$/i.test(hex)
}

const updateColorProperty = (propertyName: string, value: string) => {
  if (value) {
    document.documentElement.style.setProperty(propertyName, value)
  }
}

const queryClient = new QueryClient({
  mutationCache: new MutationCache({
    onError: (err, _variables, _context, mutation) => {
      const userContext = JSON.parse(
        localStorage.getItem('userContext') || '{}'
      )
      const userInfo = userContext?.userAccountInformation

      Sentry.withScope((scope) => {
        if (err instanceof Error) {
          scope.setContext('mutation', {
            mutationId: mutation.mutationId,
            variables: mutation.state.variables,
            queryKey: mutation.options.mutationKey,
            queryFn: mutation.options.mutationFn
          })

          if (userInfo) {
            scope.setUser({
              id: userInfo.id,
              email: userInfo.email,
              username: userInfo.username
            })
          }

          if (mutation.options.mutationKey) {
            scope.setFingerprint(
              Array.from(mutation.options.mutationKey) as string[]
            )
          } else {
            scope.setFingerprint(['unknown-mutation-key'])
          }

          Sentry.configureScope((scope) => {
            scope.setTransactionName(`Mutation ${mutation.options.mutationKey}`)
          })
          Sentry.captureException(err)
        } else {
          Sentry.captureMessage('Unknown error in mutation')
        }
      })
    }
  }),
  queryCache: new QueryCache({
    onError: (err, query) => {
      const userContext = JSON.parse(
        localStorage.getItem('userContext') || '{}'
      )
      const userInfo = userContext?.userAccountInformation

      Sentry.withScope((scope) => {
        if (err instanceof Error) {
          scope.setContext('query', {
            queryHash: query.queryHash,
            queryKey: query.queryKey,
            queryFn: query.options.queryFn
          })

          if (userInfo) {
            scope.setUser({
              id: userInfo.id,
              email: userInfo.email,
              username: userInfo.username
            })
          }

          scope.setFingerprint([query.queryHash.replace(/[0-9]/g, '0')])
          Sentry.configureScope((scope) => {
            scope.setTransactionName(`Query ${query.queryKey}`)
          })
          Sentry.captureException(err)
        } else {
          Sentry.captureMessage('Unknown error in query')
        }
      })
    }
  }),
  defaultOptions: {
    queries: {
      cacheTime: 0,
      staleTime: 0,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      onError: (err: unknown) => {
        const userContext = JSON.parse(
          localStorage.getItem('userContext') || '{}'
        )
        const userInfo = userContext?.userAccountInformation

        Sentry.withScope((scope) => {
          if (err instanceof Error) {
            scope.setContext('query', {
              error: err.message,
              stack: err.stack
            })

            if (userInfo) {
              scope.setUser({
                id: userInfo.id,
                email: userInfo.email,
                username: userInfo.username
              })
            }

            Sentry.configureScope((scope) => {
              scope.setTransactionName('Query Error')
            })
            Sentry.captureException(err)
          } else {
            Sentry.captureMessage('Unknown error in query')
          }
        })
        console.error('Query Error:', err)
      }
    },
    mutations: {
      cacheTime: 0,
      onError: (err: unknown) => {
        const userContext = JSON.parse(
          localStorage.getItem('userContext') || '{}'
        )
        const userInfo = userContext?.userAccountInformation

        Sentry.withScope((scope) => {
          if (err instanceof Error) {
            scope.setContext('mutation', {
              error: err.message,
              stack: err.stack
            })

            if (userInfo) {
              scope.setUser({
                id: userInfo.id,
                email: userInfo.email,
                username: userInfo.username
              })
            }

            Sentry.configureScope((scope) => {
              scope.setTransactionName('Mutation Error')
            })
            Sentry.captureException(err)
          } else {
            Sentry.captureMessage('Unknown error in mutation')
          }
        })
        console.error('Mutation Error:', err)
      }
    }
  }
})

const GTM_CONTAINER_ID = process.env.NEXT_PUBLIC_GTM_CONTAINER_ID || ''
const GLEAP_KEY = process.env.NEXT_PUBLIC_GLEAP_KEY || ''
const MIXPANEL_TOKEN = process.env.NEXT_PUBLIC_MIXPANEL_TOKEN || ''
const MIXPANEL_ENVIRONMENT =
  process.env.NEXT_PUBLIC_MIXPANEL_ENVIRONMENT || 'production'
const GRAFANA_FARO_NAME = process.env.NEXT_PUBLIC_GRAFANA_FARO_NAME || ''
const GRAFANA_FARO_ENVIRONMENT =
  process.env.NEXT_PUBLIC_GRAFANA_FARO_ENVIRONMENT || ''
const GRAFANA_FARO_VERSION = process.env.NEXT_PUBLIC_GRAFANA_FARO_VERSION || ''
const GRAFANA_FARO_URL = process.env.NEXT_PUBLIC_GRAFANA_FARO_URL || ''
const RECAPTCHA_SITE_KEY = process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY || ''
const CLARITY_ID = process.env.NEXT_PUBLIC_CLARITY_ID || ''
const DITTOFEED_WRITEKEY = process.env.NEXT_PUBLIC_DITTOFEED_WRITEKEY || ''
const DITTOFEED_HOST = process.env.NEXT_PUBLIC_DITTOFEED_HOST || ''

function App({ Component, pageProps }: AppProps) {
  useEffect(() => {
    if (!GLEAP_KEY) return
    Gleap.initialize(GLEAP_KEY)
  }, [])

  useEffect(() => {
    if (!MIXPANEL_TOKEN) return
    mixpanel.init(MIXPANEL_TOKEN, {
      debug: MIXPANEL_ENVIRONMENT !== 'production',
      track_pageview: true,
      persistence: 'cookie'
    })
  }, [])

  useEffect(() => {
    if (!GRAFANA_FARO_URL) return
    initializeFaro({
      url: GRAFANA_FARO_URL,
      app: {
        name: GRAFANA_FARO_NAME,
        version: GRAFANA_FARO_VERSION,
        environment: GRAFANA_FARO_ENVIRONMENT
      },
      instrumentations: [
        ...getWebInstrumentations()
        // new TracingInstrumentation()
      ]
    })
  }, [])

  useEffect(() => {
    clarity.init(CLARITY_ID)
  }, [])

  useEffect(() => {
    ;(async () => {
      if (!DITTOFEED_WRITEKEY || !DITTOFEED_HOST) return
      await DittofeedSdk.init({
        writeKey: `Basic ${DITTOFEED_WRITEKEY}`,
        host: DITTOFEED_HOST
      })
    })()
  }, [])

  useFacebookPixel()
  const router = useRouter()
  const { breakpoint } = useViewport()

  useEffect(() => {
    function updateThemeColors() {
      const primaryHex = router.query.primaryHex
      const secondaryHex = router.query.secondaryHex
      const radius = router.query.radius

      if (primaryHex && isHexValid(`#${primaryHex}`)) {
        const primaryColor = `#${primaryHex}`
        updateColorProperty('--primary-100', lighten(0.3, primaryColor))
        updateColorProperty('--primary-200', lighten(0.09, primaryColor))
        updateColorProperty('--primary', primaryColor)
        updateColorProperty('--primary-700', darken(0.18, primaryColor))
      }

      if (secondaryHex && isHexValid(`#${secondaryHex}`)) {
        const secondaryColor = `#${secondaryHex}`
        updateColorProperty('--secondary-100', lighten(0.28, secondaryColor))
        updateColorProperty('--secondary-200', lighten(0.24, secondaryColor))
        updateColorProperty('--secondary', secondaryColor)
        updateColorProperty(
          '--secondary-700',
          adjustHue(-12, darken(0.23, secondaryColor))
        )
        // updateColorProperty('--secondary-700', darken(0.18, secondaryColor))
      }

      let radiusValue = '8'
      if (typeof radius === 'string') {
        const numericRadius = radius.replace(/[a-zA-Z]/g, '')
        if (numericRadius) {
          radiusValue = numericRadius
        }
      }
      document.documentElement.style.setProperty('--radius', `${radiusValue}px`)
    }

    updateThemeColors()
  }, [router])

  useEffect(() => {
    function handleResize() {
      const vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    }

    window.addEventListener('resize', handleResize)

    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    if (window.location.hostname === 'signater-app.pages.dev') {
      router.push('https://app.signater.com')
    }
  }, [router])

  useEffect(() => {
    const appDomain =
      typeof window === 'undefined' ? '' : window.location.hostname
    const visitDate = new Date().toISOString()

    const setCookie = (name: string, value: string) => {
      if (value) {
        Cookies.set(name, value, {
          domain: appDomain,
          path: '/',
          secure: true,
          sameSite: 'None' // Allow subdomains to access the cookie
        })
      }
    }

    const tad = Cookies.get('TrackingAppDomain')
    if (!tad || tad !== appDomain) {
      setCookie('TrackingAppDomain', appDomain)
    }

    if (!Cookies.get('TrackingGeneratedAtUtc')) {
      setCookie('TrackingGeneratedAtUtc', visitDate)
    }

    // Convert all query parameters to lower case
    const query = Object.fromEntries(
      Object.entries(router.query).map(([key, value]) => [
        key.toLowerCase(),
        value
      ])
    )

    const {
      affiliate_id,
      utm_source,
      utm_medium,
      utm_campaign,
      utm_term,
      utm_content,
      referrer
    } = query

    const shouldSetCookies =
      affiliate_id ||
      utm_source ||
      utm_medium ||
      utm_campaign ||
      utm_term ||
      utm_content ||
      referrer

    if (!shouldSetCookies) return

    // Definindo cookies
    setCookie('TrackingAffiliateId', affiliate_id as string)
    setCookie('TrackingUtmSource', utm_source as string)
    setCookie('TrackingUtmMedium', utm_medium as string)
    setCookie('TrackingUtmCampaign', utm_campaign as string)
    setCookie('TrackingUtmTerm', utm_term as string)
    setCookie('TrackingUtmContent', utm_content as string)
    setCookie('TrackingReferrer', referrer as string)
  }, [router.query])

  return (
    <React.StrictMode>
      <Head>
        <title>Signater App</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
        <link
          rel="shortcut icon"
          href="/assets/logos/signater/yellow-black-square.png"
        />
        <link
          rel="apple-touch-icon"
          href="/assets/logos/signater/yellow-black-square.png"
        />
        <DefaultSeo {...SEO} />
      </Head>
      <GoogleReCaptchaProvider useEnterprise reCaptchaKey={RECAPTCHA_SITE_KEY}>
        <ErrorBoundary>
          <QueryClientProvider client={queryClient}>
            {process.env.NEXT_PUBLIC_APP_ENV === 'local' &&
              breakpoint !== 'xs' &&
              breakpoint !== 'sm' && (
                <ReactQueryDevtools initialIsOpen={false} />
              )}
            <ThemeProvider
              attribute="class"
              enableSystem={false}
              themes={['dark', 'light']}
            >
              <Providers>
                <Component {...pageProps} />
                <TrackingHeadScript id={GTM_CONTAINER_ID} isGTM={true} />
                <GoogleAnalytics trackPageViews />
              </Providers>
            </ThemeProvider>
          </QueryClientProvider>
        </ErrorBoundary>
      </GoogleReCaptchaProvider>
    </React.StrictMode>
  )
}

export default App
