import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import useApi from '@/hooks/useApi'
import {
  CreateWebhookRequest,
  CreateWebhookResponse,
  GetListWebhooksActiveResponse,
  GetListWebhooksInactiveResponse,
  GetWebhookResponse,
  GetWebhooksCLIResponse,
  UpdateWebhookRequest,
  UpdateWebhookRequestWithId,
  UpdateWebhooksCLIRequest
} from './useWebhooksApi.types'
import { QueryKeys } from '@/hooks/useApi/useApi.types'
import { useAuth } from '@/providers'

const useWebhooksApi = () => {
  const { handleApi, queryConfig } = useApi()
  const { isLogged, isAdmin } = useAuth()
  const queryClient = useQueryClient()

  // GET {{api}}/v1/core/webhooks/active?search=&pageNumber=1&pageSize=10
  const useGetListWebhooksActive = (
    enabled = false,
    search: string | null,
    pageNumber = 0,
    pageSize = 10
  ) => {
    const url = '/core/webhooks/active'
    let queryParams = `?pageNumber=${pageNumber + 1}&pageSize=${pageSize}`
    if (search && search !== null) {
      queryParams += `&search=${encodeURIComponent(search)}`
    }

    return useQuery({
      queryKey: [
        QueryKeys.WebhooksActive,
        search,
        pageNumber,
        pageSize,
        enabled
      ],
      queryFn: () =>
        handleApi<void, GetListWebhooksActiveResponse>(
          `${url}${queryParams}`,
          'GET'
        ),
      ...queryConfig,
      enabled: Boolean(!!isLogged && !!isAdmin && !!enabled)
    })
  }

  // GET {{api}}/v1/core/webhooks/inactive?search=&pageNumber=1&pageSize=10
  const useGetListWebhooksInactive = (
    enabled = false,
    search: string | null,
    pageNumber = 0,
    pageSize = 10
  ) => {
    const url = '/core/webhooks/inactive'
    let queryParams = `?pageNumber=${pageNumber + 1}&pageSize=${pageSize}`
    if (search && search !== null) {
      queryParams += `&search=${encodeURIComponent(search)}`
    }

    return useQuery({
      queryKey: [
        QueryKeys.WebhooksInactive,
        search,
        pageNumber,
        pageSize,
        enabled
      ],
      queryFn: () =>
        handleApi<void, GetListWebhooksInactiveResponse>(
          `${url}${queryParams}`,
          'GET'
        ),
      ...queryConfig,
      enabled: Boolean(!!isLogged && !!isAdmin && !!enabled)
    })
  }

  // GET {{api}}/v1/core/webhooks/b1e24056-c1e3-4359-93d5-c87b95810053
  const useGetWebhook = (enabled = false, id: string | null) =>
    useQuery({
      queryKey: [QueryKeys.Webhook, id],
      queryFn: () =>
        handleApi<void, GetWebhookResponse>(`/core/webhooks/${id}`, 'GET'),
      ...queryConfig,
      enabled: Boolean(!!isLogged && !!isAdmin && !!enabled && !!id)
    })

  // POST {{api}}/v1/core/webhooks
  const useCreateWebhook = () =>
    useMutation({
      mutationFn: (data: CreateWebhookRequest) => {
        return handleApi<CreateWebhookRequest, CreateWebhookResponse>(
          '/core/webhooks',
          'POST',
          data
        )
      },
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.WebhooksActive]
        })
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.WebhooksInactive]
        })
      }
    })

  const useUpdateWebhook = () =>
    useMutation({
      mutationFn: ({ id, ...data }: UpdateWebhookRequestWithId) => {
        return handleApi<UpdateWebhookRequest, void>(
          `/core/webhooks/${id}`,
          'PUT',
          data
        )
      },
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.WebhooksActive]
        })
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.WebhooksInactive]
        })
      }
    })

  // POST {{api}}/v1/core/webhooks/:webhookId/activate
  const useActivateWebhook = () =>
    useMutation({
      mutationFn: (webhookId: string) => {
        return handleApi<void, void>(
          `/core/webhooks/${webhookId}/activate`,
          'POST'
        )
      },
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.WebhooksActive]
        })
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.WebhooksInactive]
        })
      }
    })

  // POST {{api}}/v1/core/webhooks/:webhookId/deactivate
  const useDeactivateWebhook = () =>
    useMutation({
      mutationFn: (webhookId: string) => {
        return handleApi<void, void>(
          `/core/webhooks/${webhookId}/deactivate`,
          'POST'
        )
      },
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.WebhooksActive]
        })
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.WebhooksInactive]
        })
      }
    })

  // GET {{api}}/v1/core/webhooks/cli
  const useGetWebhooksCLI = (enabled = false) =>
    useQuery({
      queryKey: [QueryKeys.WebhooksCLI, enabled],
      queryFn: () =>
        handleApi<void, GetWebhooksCLIResponse>(`/core/webhooks/cli`, 'GET'),
      ...queryConfig,
      enabled: Boolean(!!isLogged && !!isAdmin && !!enabled)
    })

  // PUT {{api}}/v1/core/webhooks/cli
  const useUpdateWebhooksCLI = () =>
    useMutation({
      mutationFn: (data: UpdateWebhooksCLIRequest) => {
        return handleApi<UpdateWebhooksCLIRequest, void>(
          `/core/webhooks/cli`,
          'PUT',
          data
        )
      },
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.WebhooksCLI]
        })
      }
    })

  return {
    useGetListWebhooksActive,
    useGetListWebhooksInactive,
    useGetWebhook,
    useCreateWebhook,
    useUpdateWebhook,
    useActivateWebhook,
    useDeactivateWebhook,
    useGetWebhooksCLI,
    useUpdateWebhooksCLI
  }
}

export default useWebhooksApi
