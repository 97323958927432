import React from 'react'
import { cn } from '@/lib/utils'
import { Typography } from '@/ui/atoms'

type Props = {
  icon: React.ReactNode
  value: number | string
  label: string
  description: string
  className?: string
}

const CardStatsBadge: React.FC<Props> = ({
  icon,
  value,
  label,
  description,
  className
}) => {
  return (
    <div
      className={cn('w-full p-5 border border-gray-300 rounded-2xl', className)}
    >
      <Typography
        variant="title-3xl-bold"
        className="flex my-3 font-bold text-primary-700"
      >
        {icon} {value}+
      </Typography>
      <Typography
        variant="title-xl-medium"
        className="my-2 font-extrabold text-gray-900"
      >
        {label}
      </Typography>
      <Typography variant="text-sm-regular" className="text-gray-600">
        {description}
      </Typography>
    </div>
  )
}

export default CardStatsBadge
