import React, { useCallback } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { useAuth } from '@/providers/Auth'
import { Typography } from '@/atoms/index'
import useTranslation from '@/hooks/useTranslation/useTranslation'
import { showToast } from '@/ui/atoms/index'
import { Button } from '@/ui/atoms/shadcn'
import { Form, RenderFormField } from '@/ui/molecules'

type FormValues = {
  email: string
}

const ForgotPasswordInsertEmail = () => {
  const { t, isReady } = useTranslation(['forgotPassword', 'validations'], true)
  const { loading, sendCodeRecoveryPassword } = useAuth()
  const { push } = useRouter()
  const { executeRecaptcha } = useGoogleReCaptcha()

  const formSchema = z.object({
    email: z
      .string()
      .min(1, { message: t?.requiredField })
      .min(2, { message: t?.errorMinLength?.(2) })
      .max(100, { message: t?.errorMaxLength?.(100) })
      .email({ message: t?.errorInvalidEmail })
  })

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: ''
    }
  })

  const onSubmit = useCallback(
    async (values) => {
      const token = executeRecaptcha
        ? await executeRecaptcha('password_reset_request')
        : ''
      try {
        const response = await sendCodeRecoveryPassword(values.email, token)
        if (response?.success) {
          push(`/forgot-password?email=${values.email}`)
        }
        form.setValue('email', values.email)
      } catch {
        showToast.error(t?.toasts?.errorSendCode)
      }
    },
    [
      executeRecaptcha,
      form,
      push,
      sendCodeRecoveryPassword,
      t?.toasts?.errorSendCode
    ]
  )

  return (
    <>
      <div className="flex flex-col items-start justify-start gap-6">
        <div className="flex flex-col items-start justify-start gap-1">
          <Typography
            type="h1"
            variant="title-2xl-regular"
            className="text-gray-700 dark:text-gray-700"
            showSkeleton={!isReady}
          >
            {t?.title}
          </Typography>
          <Typography
            variant="text-sm-regular"
            className="text-gray-700 dark:text-gray-700"
            showSkeleton={!isReady}
          >
            {t?.subTitle}
          </Typography>
        </div>
      </div>
      <div className="flex flex-col gap-6">
        <Form {...form} onSubmit={onSubmit}>
          <RenderFormField
            control={form.control}
            name="email"
            type="input"
            showSkeleton={!isReady}
            {...form.formState.errors.email}
            {...t?.form?.email}
          />
          <Button type="submit" showSkeleton={!isReady} loading={loading}>
            {t?.buttonSend}
          </Button>
        </Form>
        <div className="inline-flex items-center justify-center gap-1 sm:gap-2">
          <Link href="/login" passHref>
            <Typography
              variant="text-sm-semibold"
              className="text-center cursor-pointer text-secondary-700"
            >
              {t?.buttonBack}
            </Typography>
          </Link>
        </div>
      </div>
    </>
  )
}

export default ForgotPasswordInsertEmail
