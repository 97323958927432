import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useRouter } from 'next/router'
import { parsePhoneNumber } from 'react-phone-number-input'
import useLocalStorage from '@/hooks/useLocalStorage'
import useTranslation from '@/hooks/useTranslation'
import { useViewport } from '@/hooks/useViewport'
import { useLocale, useQueryParams } from '@/providers/index'
import { Lang } from '@/types/global'
import { StepType } from './Envelope.types'
import type {
  ActionStepType,
  CurrentSignerContact,
  CurrentStepType,
  CurrentValuesEnvelope,
  EnvelopeContextType,
  CurrentSignerActionType,
  LoadingPdfByDocumentIdType,
  ModalConfirmErrorPublishType,
  ModalHelpInfoType,
  SaveEnvelopeType
} from './Envelope.types'
import type {
  CurrentActionEnvelopeType,
  ISelectedSignerMarkData,
  SignerEnvelopeInitial,
  Props,
  ViewType
} from './Envelope.types'
import { HEIGHT_MARK, WIDTH_MARK } from '@/constants/sizesPdf'
import useDocument from '@/hooks/api/ecm/useDocumentApi/useDocumentApi'
import UseEnvelopeApi from '@/hooks/api/ecm/useEnvelopeApi/useEnvelopeApi'
import {
  DocumentEnvelope,
  EnvelopeRequest,
  SignerEnvelopeStatus
} from '@/hooks/api/ecm/useEnvelopeApi/useEnvelopeApi.types'
import { SignMarkWithSignerType } from '@/molecules/PdfViewer/PdfViewer.types'
import { showToast } from '@/ui/atoms/index'
import { CurrentVaultId } from '@/ui/atoms/SelectVault/SelectVault'

const store = createContext<EnvelopeContextType>({} as EnvelopeContextType)
const { Provider } = store

function getSignMarksWithSignerData(
  signers: SignerEnvelopeInitial[],
  documentId: string
): SignMarkWithSignerType[] {
  const signMarksWithSignerData: SignMarkWithSignerType[] = []

  signers?.forEach((signer) => {
    const currentSignerHasReviewed =
      signer?.status === SignerEnvelopeStatus.Approved ||
      signer?.status === SignerEnvelopeStatus.Rejected

    signer?.signMarks.forEach((signMark) => {
      const signMarkWithSignerData = {
        ...signMark,
        signer: {
          name: signer.name,
          email: signer.email,
          role: signer.role,
          title: signer.title,
          currentSignerHasReviewed
        }
      }
      signMarksWithSignerData.push(signMarkWithSignerData)
    })
  })

  const data = signMarksWithSignerData
    .map((signMark) => {
      return {
        ...signMark
      }
    })
    .filter((signMark) => String(signMark.documentId) === String(documentId))

  return data
}

export function EnvelopeContext({ children }: Props) {
  const { t } = useTranslation(['envelope', 'language'], true)
  const { lang } = useLocale()
  const [currentStep, setCurrentStep] = useState<CurrentStepType>({
    stepName: StepType.DETAILS,
    stepNumber: 1
  })
  const [viewType, setViewType] = useState<ViewType>({
    type: 'envelope',
    templateId: null
  })
  const [canAddSignature, setCanAddSignature] = useState(false)
  const [selectedSignerMarkData, setSelectedSignerMarkData] =
    useState<ISelectedSignerMarkData>({
      id: null,
      type: null
    })
  const [document, setDocument] = useState<DocumentEnvelope | null>(null)
  const [currentVaultId, setCurrentVaultId] = useLocalStorage<CurrentVaultId>(
    'currentVaultId',
    null
  )
  const [currentValuesEnvelope, setCurrentValuesEnvelope] =
    useState<CurrentValuesEnvelope>({
      vault: { label: '', value: '' },
      name: '',
      message: '',
      privateDescription: '',
      publicDescription: '',
      reviewReminder: true,
      buttonShowAdvancedSettings: false,
      toBePublished: false,
      toBePublishedAtUtc: null,
      hasExpiration: false,
      expirationReminder: false,
      expiresAtUtc: null,
      language: lang
    } as CurrentValuesEnvelope)
  const [documents, setDocuments] = useState<DocumentEnvelope[]>([])
  const [signers, setSigners] = useState<SignerEnvelopeInitial[]>([])
  const [currentActionEnvelope, setCurrentActionEnvelope] =
    useState<CurrentActionEnvelopeType>({
      id: null,
      action: null
    })
  const [loadingPdfByDocumentId, setLoadingPdfByDocumentId] =
    useState<LoadingPdfByDocumentIdType>({
      documentId: null,
      loading: false
    })
  const [currentSignerAction, setCurrentSignerAction] =
    useState<CurrentSignerActionType>({
      id: null,
      isOpen: false,
      action: null
    })
  const [currentSignerContact, setCurrentSignerContact] =
    useState<CurrentSignerContact | null>(null)
  const [modalConfirmErrorPublish, setModalConfirmErrorPublish] =
    useState<ModalConfirmErrorPublishType>({
      open: false,
      typeError: null
    })
  const [alreadySaved, setAlreadySaved] = useState(false)
  const [finishedInitialSetup, setFinishedInitialSetup] = useState(false)
  const [modalHelpInfo, setModalHelpInfo] = useState<ModalHelpInfoType>({
    open: false,
    type: null
  })
  const [valuesDocumentTemplateFiller, setValuesDocumentTemplateFiller] =
    useState<Record<string, string>>({})

  const { push, route } = useRouter()
  const { breakpoint } = useViewport()
  const {
    getQueryParam,
    setQueryParam,
    isReady: isReadyQueryParams
  } = useQueryParams()
  const templateIdQueryParam = getQueryParam<string>('templateId', null)
  const documentsIdsQueryParam = getQueryParam<string>('documents', null)
  const {
    useCreateEnvelope,
    usePublishEnvelope,
    useGetEnvelope,
    useUpdateEnvelope
  } = UseEnvelopeApi()
  const { useGetDocuments } = useDocument()
  const {
    mutateAsync: mutateAsyncCreateEnvelope,
    isLoading: isLoadingCreateEnvelope
  } = useCreateEnvelope()
  const {
    mutateAsync: mutateAsyncPublishEnvelope,
    isLoading: isLoadingPublishEnvelope
  } = usePublishEnvelope()
  const { data: dataEnvelope, isError: isErrorEnvelope } = useGetEnvelope(
    currentActionEnvelope?.action === 'edit' && currentActionEnvelope?.id
      ? currentActionEnvelope?.id
      : null
  )
  const {
    mutateAsync: mutateAsyncUpdateEnvelope,
    isLoading: isLoadingUpdateEnvelope
  } = useUpdateEnvelope()
  const { mutateAsync: mutateAsyncGetDocuments } = useGetDocuments()

  const isCompactDevice = useMemo(() => {
    return breakpoint === 'xs' || breakpoint === 'sm'
  }, [breakpoint])

  const resetEnvelope = useCallback(() => {
    setCurrentValuesEnvelope({} as CurrentValuesEnvelope)
    setDocuments([])
    setSigners([])
    setCurrentActionEnvelope({
      action: null,
      id: null
    })
  }, [setCurrentActionEnvelope])

  useEffect(() => {
    if (route !== '/envelope' && route !== '/envelope/[id]/edit') {
      resetEnvelope()
    }
    if (isReadyQueryParams && templateIdQueryParam) {
      setViewType({
        type: 'template',
        templateId: templateIdQueryParam
      })
      setQueryParam('templateId', null)
    }
    if (isReadyQueryParams && documentsIdsQueryParam) {
      mutateAsyncGetDocuments({
        documentIds: documentsIdsQueryParam?.split(',')
      }).then((response) => {
        const newDocuments = response?.items?.map((document, index) => ({
          id: document.id,
          index,
          name: document.name,
          privateDescription: document.privateDescription,
          publicDescription: document.publicDescription,
          pageSizes: document.pageSizes
        }))
        setDocuments(newDocuments as DocumentEnvelope[])
      })
      setQueryParam('documents', null)
    }
    if (isErrorEnvelope && currentActionEnvelope.action === 'edit') {
      push('/')
    }
  }, [
    currentActionEnvelope.action,
    documentsIdsQueryParam,
    isErrorEnvelope,
    isReadyQueryParams,
    mutateAsyncGetDocuments,
    push,
    resetEnvelope,
    route,
    setQueryParam,
    templateIdQueryParam
  ])

  const noSigners = useMemo(() => {
    if (!signers) return true
    return (
      signers?.length === 0 ||
      ((signers?.length || 0) > 0 && signers?.every((e) => !e.email))
    )
  }, [signers])

  const marks = useMemo(() => {
    return getSignMarksWithSignerData(signers, document?.id as string)
  }, [signers, document?.id])

  const hasReviewed = useMemo(() => {
    const allowedStatus = [
      SignerEnvelopeStatus.Approved,
      SignerEnvelopeStatus.Rejected
    ]
    return signers.some((signer) =>
      allowedStatus.includes(signer.status as SignerEnvelopeStatus)
    )
  }, [signers])

  const handlePublishEnvelope = useCallback(
    async (id: string) => {
      try {
        await mutateAsyncPublishEnvelope({
          id: id
        })
        showToast.success(t?.toasts?.successPublishEnvelope)
        const vaultId =
          typeof currentValuesEnvelope?.vault === 'object'
            ? currentValuesEnvelope?.vault?.value
            : currentValuesEnvelope?.vault
        push(vaultId ? `/vaults/${vaultId}` : '/')
      } catch (error: any) {
        if (
          error.response.status === 402 &&
          error.response.statusText === 'Payment Required' &&
          error.response.data
        ) {
          setModalConfirmErrorPublish({
            open: true,
            typeError: error.response.data?.shouldBuy
          })
          return
        }
        showToast.error(t?.toasts?.errorPublishEnvelope)
        console.log('error', error)
      }
    },
    [
      mutateAsyncPublishEnvelope,
      t?.toasts?.successPublishEnvelope,
      t?.toasts?.errorPublishEnvelope,
      currentValuesEnvelope?.vault,
      push
    ]
  )

  const handleSaveEnvelope = useCallback(
    async ({ values, publish, removeSchedule }: SaveEnvelopeType) => {
      const currentValues = values || currentValuesEnvelope

      if (
        currentValues?.toBePublishedAtUtc &&
        new Date(currentValues?.toBePublishedAtUtc) < new Date()
      ) {
        setModalConfirmErrorPublish({
          open: true,
          typeError: 'PublicationDateBeforeNow'
        })
        return
      }
      if (
        currentValues?.expiresAtUtc &&
        new Date(currentValues?.expiresAtUtc) < new Date()
      ) {
        setModalConfirmErrorPublish({
          open: true,
          typeError: 'ExpirationDateBeforeNow'
        })
        return
      }
      if (
        !publish &&
        currentValuesEnvelope?.toBePublishedAtUtc &&
        currentValuesEnvelope.toBePublished &&
        modalConfirmErrorPublish.typeError !== 'RemoveSchedule' &&
        !removeSchedule
      ) {
        setModalConfirmErrorPublish({
          open: true,
          typeError: 'RemoveSchedule'
        })
        return
      }

      const signersData = signers?.map((signer, index) => {
        const phoneIddValue = signer.shouldEnforceSmsValidation
          ? parsePhoneNumber(signer.phoneNumber)?.countryCallingCode
          : null

        const phoneNumberValue = signer.shouldEnforceSmsValidation
          ? parsePhoneNumber(signer.phoneNumber)?.nationalNumber
          : null

        const extraPropsSigner =
          signer.id && !signer.id.includes('fake-id-')
            ? {
                id: signer.id
              }
            : {}

        return {
          ...extraPropsSigner,
          index: index,
          name: signer.name,
          email: signer.email,
          role: signer.role,
          title: signer.title,
          shouldEnforceEmailValidation: signer.shouldEnforceEmailValidation,
          shouldEnforcePasscodeValidation:
            signer.shouldEnforcePasscodeValidation,
          passcode: signer.shouldEnforcePasscodeValidation
            ? signer.passcode
            : null,
          passcodeHint: signer.shouldEnforcePasscodeValidation
            ? signer.passcodeHint
            : null,
          shouldEnforceSmsValidation: signer.shouldEnforceSmsValidation,
          phoneIdd: signer.shouldEnforceSmsValidation ? phoneIddValue : null,
          phoneNumber: signer.shouldEnforceSmsValidation
            ? phoneNumberValue
            : null,
          signMarks: signer.signMarks.map((signMark) => {
            const { id, ...rest } = signMark

            const extraPropsSignMark = !id?.includes('fake-id-') ? { id } : {}
            const currentDocumentSize = documents
              ?.find((document) => document.id === rest.documentId)
              ?.pageSizes?.find((size) => size.page === rest.page) as any
            const x =
              rest.x + WIDTH_MARK > currentDocumentSize?.width
                ? currentDocumentSize?.width - WIDTH_MARK
                : rest.x
            const y =
              rest.y + HEIGHT_MARK > currentDocumentSize?.height
                ? currentDocumentSize?.height - HEIGHT_MARK
                : rest.y

            return {
              ...extraPropsSignMark,
              ...rest,
              x: x,
              y: y
            }
          })
        }
      })

      const vaultId =
        typeof currentValuesEnvelope?.vault === 'object'
          ? currentValuesEnvelope?.vault?.value
          : currentValuesEnvelope?.vault
      const envelopePayload = {
        vaultId: vaultId || '',
        name: currentValuesEnvelope?.name || '',
        privateDescription: currentValuesEnvelope?.privateDescription || null,
        publicDescription: currentValuesEnvelope?.publicDescription || null,
        message: currentValuesEnvelope?.message || null,
        reviewReminder: currentValuesEnvelope?.reviewReminder,
        expiresAtUtc: currentValuesEnvelope?.expiresAtUtc,
        expirationReminder: currentValuesEnvelope?.expirationReminder,
        toBePublishedAtUtc: !removeSchedule
          ? currentValuesEnvelope?.toBePublishedAtUtc
          : null,
        language: currentValuesEnvelope?.language || lang,
        markupOrientation: currentValuesEnvelope?.markupOrientation || 'Bottom',
        signInOrder: currentValuesEnvelope?.signInOrder,
        documents: documents?.map((document, index) => ({
          ...document,
          index
        })),
        signers: signersData
      } as EnvelopeRequest

      if (currentActionEnvelope.action === 'edit' && currentActionEnvelope.id) {
        try {
          await mutateAsyncUpdateEnvelope({
            id: currentActionEnvelope.id,
            ...envelopePayload
          })
          setAlreadySaved(true)

          if (publish && !currentValuesEnvelope.toBePublished) {
            await handlePublishEnvelope(currentActionEnvelope.id)
            return
          }
          showToast.success(t?.toasts?.successUpdateEnvelope)
          push(vaultId ? `/vaults/${vaultId}` : '/')
        } catch {
          showToast.error(t?.toasts?.errorUpdateEnvelope)
        }

        return
      }

      try {
        const createEnvelopeResponse =
          await mutateAsyncCreateEnvelope(envelopePayload)
        setAlreadySaved(true)
        setCurrentActionEnvelope({
          action: 'create',
          id: createEnvelopeResponse?.envelopeId as string
        })

        if (
          publish &&
          createEnvelopeResponse?.envelopeId &&
          !currentValuesEnvelope.toBePublished
        ) {
          await handlePublishEnvelope(createEnvelopeResponse?.envelopeId)
          return
        }
        showToast.success(t?.toasts?.successCreateEnvelope)
        push(vaultId ? `/vaults/${vaultId}` : '/')
      } catch (error: any) {
        if (
          error.response.status === 402 &&
          error.response.statusText === 'Payment Required' &&
          error.response.data
        ) {
          setModalConfirmErrorPublish({
            open: true,
            typeError: error.response.data?.shouldBuy
          })

          return
        }
        if (
          error.response.status === 429 &&
          error.response.statusText === 'Too Many Requests'
        ) {
          setModalHelpInfo({
            open: true,
            type: 'errorTooManyRequests'
          })
          return
        }
        setModalHelpInfo({
          open: true,
          type: 'errorCreateEnvelope'
        })
      }
    },
    [
      currentActionEnvelope.action,
      currentActionEnvelope.id,
      currentValuesEnvelope,
      documents,
      handlePublishEnvelope,
      lang,
      modalConfirmErrorPublish.typeError,
      mutateAsyncCreateEnvelope,
      mutateAsyncUpdateEnvelope,
      push,
      signers,
      t?.toasts?.errorUpdateEnvelope,
      t?.toasts?.successCreateEnvelope,
      t?.toasts?.successUpdateEnvelope
    ]
  )

  const handleDeleteSigner = useCallback((id: string) => {
    setSigners((prev) => {
      if (!prev) return prev
      return prev.filter((signer) => signer.id !== id)
    })
  }, [])

  const stepsOrder = useMemo(
    () => [
      StepType.DETAILS,
      ...(isCompactDevice && !hasReviewed ? [StepType.DOCUMENTS] : []),
      StepType.SIGNERS,
      StepType.SIGNATURE_MARKER
    ],
    [hasReviewed, isCompactDevice]
  )

  const handleActionStep = useCallback(
    (action: ActionStepType) => {
      setCurrentStep((prev) => {
        if (!prev) return prev

        const currentIndex = stepsOrder.findIndex(
          (step) => step === prev.stepName
        )
        if (currentIndex === -1) return prev

        let nextIndex = currentIndex
        if (action === 'next' && currentIndex < stepsOrder.length - 1) {
          nextIndex = currentIndex + 1
        } else if (action === 'previous' && currentIndex > 0) {
          nextIndex = currentIndex - 1
        }

        if (currentIndex !== nextIndex) {
          return {
            stepNumber: prev.stepNumber + (action === 'next' ? 1 : -1),
            stepName: stepsOrder[nextIndex]
          }
        }

        return prev
      })
    },
    [stepsOrder]
  )

  const valuesSelectDocuments = useMemo(() => {
    return documents?.map((file) => ({
      id: file?.id,
      value: file?.name,
      label: file?.name?.replace('.pdf', '')
    }))
  }, [documents])

  const loadingEnvelopeSave = useMemo(
    () =>
      isLoadingCreateEnvelope ||
      isLoadingUpdateEnvelope ||
      isLoadingPublishEnvelope,
    [isLoadingCreateEnvelope, isLoadingPublishEnvelope, isLoadingUpdateEnvelope]
  )

  return (
    <Provider
      value={{
        currentStep,
        setCurrentStep,
        canAddSignature,
        setCanAddSignature,
        selectedSignerMarkData,
        setSelectedSignerMarkData,
        document,
        setDocument,
        noSigners,
        currentVaultId,
        setCurrentVaultId,
        documents,
        setDocuments,
        marks,
        handleDeleteSigner,
        signers,
        setSigners,
        isLoadingCreateEnvelope,
        currentActionEnvelope,
        setCurrentActionEnvelope,
        loadingEnvelopeSave,
        loadingPdfByDocumentId,
        setLoadingPdfByDocumentId,
        currentValuesEnvelope,
        setCurrentValuesEnvelope,
        viewType,
        setViewType,
        currentSignerAction,
        setCurrentSignerAction,
        currentSignerContact,
        setCurrentSignerContact,
        isCompactDevice,
        handleActionStep,
        stepsOrder,
        valuesSelectDocuments,
        handleSaveEnvelope,
        hasReviewed,
        modalConfirmErrorPublish,
        setModalConfirmErrorPublish,
        handlePublishEnvelope,
        alreadySaved,
        finishedInitialSetup,
        setFinishedInitialSetup,
        modalHelpInfo,
        setModalHelpInfo,
        dataEnvelope,
        valuesDocumentTemplateFiller,
        setValuesDocumentTemplateFiller
      }}
    >
      {children}
    </Provider>
  )
}

const useEnvelope = () => useContext(store)

export { EnvelopeContextType, useEnvelope }
