import * as React from 'react'
import {
  InformationCircleIcon,
  MagnifyingGlassIcon
} from '@heroicons/react/20/solid'
import { ChevronDownIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { CaretSortIcon, CrossCircledIcon } from '@radix-ui/react-icons'
import Image from 'next/image'
import useTranslation from '@/hooks/useTranslation'
import Checkbox from '../Checkbox'
import { Label } from '../shadcn'
import Typography from '../Typography'
import { cn } from '@/lib/utils'
import { Badge } from '@/ui/atoms/shadcn/Badge'
import { Button } from '@/ui/atoms/shadcn/Button'
import {
  Popover,
  PopoverContent,
  PopoverTrigger
} from '@/ui/atoms/shadcn/Popover'

type Props = {
  name: string
  options: {
    label: string
    value: string
    icon?: React.ComponentType<{ className?: string }>
  }[]
  value: string[] // Para ser um componente controlado
  onValueChange: (value: string[]) => void
  placeholder?: string
  maxCount?: number
  modalPopover?: boolean
  className?: string
  isLoading?: boolean
  searchable?: boolean
  error?: string
  label?: string
  showSkeleton?: boolean
  disabled?: boolean
}

export const MultiSelect: React.FC<Props> = ({
  name,
  error,
  label,
  options,
  value,
  onValueChange,
  placeholder = 'Select options',
  maxCount = 2,
  modalPopover = false,
  className,
  isLoading,
  searchable = false,
  showSkeleton = false,
  disabled = false
}) => {
  const { t, isReady } = useTranslation('multiSelect')
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false)
  const [valueSearch, setValueSearch] = React.useState<string | null>(null)

  const toggleOption = React.useCallback(
    (option: string) => {
      if (option === 'all') {
        if (value.length === options?.length) {
          onValueChange([])
        } else {
          const allValues = options.map((option) => option.value)
          onValueChange(allValues)
        }
        return
      }

      const newSelectedValues = value.includes(option)
        ? value.filter((v) => v !== option)
        : [...value, option]
      onValueChange(newSelectedValues)
    },
    [onValueChange, options, value]
  )

  const handleClickClear = React.useCallback(() => {
    onValueChange([])
    setIsPopoverOpen(false)
  }, [onValueChange])

  const handleClickClose = React.useCallback(() => {
    setIsPopoverOpen(false)
  }, [])

  const isEmpty = React.useMemo(
    () => options?.length === 0 && !isLoading,
    [isLoading, options?.length]
  )

  const dataOptions = React.useMemo(() => {
    if (!isReady) return [...(options || [])]
    return [t?.allOption, ...(options || [])]
  }, [isReady, options, t])

  if (showSkeleton) {
    return (
      <div className="flex flex-col w-full gap-1">
        <div className="w-24 h-3 md:h-4 skeleton" />
        <div className="w-full h-8 min-w-44 md:h-10 skeleton" />
      </div>
    )
  }

  return (
    <div
      className={cn(
        'relative w-full flex justify-end flex-col gap-1',
        error && 'border-error-500 hover:border-error-500'
      )}
    >
      <Label onClick={() => setIsPopoverOpen(true)} htmlFor={name}>
        {label}
      </Label>
      <Popover
        open={isPopoverOpen}
        onOpenChange={(open) => {
          if (disabled) return
          setIsPopoverOpen(open)
        }}
      >
        <PopoverTrigger asChild>
          <div
            className={cn(
              'flex items-center gap-2 bg-accent-100 text-accent-900 dark:bg-accent-500 ring-0 border border-gray-300 w-full text-sm px-[13px] min-h-10 h-auto rounded-md shadow-sm font-normal leading-tight outline-none py-2',
              error ? 'border-error-500' : '',
              disabled
                ? 'cursor-not-allowed opacity-50'
                : 'cursor-pointer hover:border-primary-200 focus:border-primary-700 focus:ring-primary-700'
            )}
          >
            {value.length > 0 ? (
              <div className="flex justify-between items-center w-full flex-1">
                <div className="flex flex-wrap items-center flex-1 gap-[2px]">
                  {value.slice(0, maxCount)?.map((val) => {
                    const option = options?.find((o) => o.value === val)
                    const IconComponent = option?.icon

                    return (
                      <Badge key={val} variant="neutral" size="xs">
                        {IconComponent && (
                          <IconComponent className="h-4 w-4 mr-2" />
                        )}
                        {option?.label}
                        <CrossCircledIcon
                          className="ml-2 h-4 w-4 cursor-pointer"
                          onClick={(event) => {
                            event.stopPropagation()
                            toggleOption(val)
                          }}
                        />
                      </Badge>
                    )
                  })}
                  {value.length > maxCount && (
                    <Badge
                      variant="neutral"
                      size="xs"
                      className="bg-gray-800 text-white"
                    >
                      {t?.flagMore?.(value.length - maxCount)}
                    </Badge>
                  )}
                </div>
                <CaretSortIcon className="w-4 h-4 opacity-50" />
              </div>
            ) : (
              <div className="flex items-center justify-between w-full flex-1">
                <span className="text-sm text-gray-400 font-normal leading-tight">
                  {placeholder}
                </span>
                <CaretSortIcon className="w-4 h-4 opacity-50" />
              </div>
            )}
          </div>
        </PopoverTrigger>
        <PopoverContent
          className="w-auto p-0 z-100"
          align="start"
          side="bottom"
          onEscapeKeyDown={() => setIsPopoverOpen(false)}
        >
          {searchable && (
            <div className="flex items-center h-10 border-b border-gray-200">
              <MagnifyingGlassIcon
                className="absolute w-5 h-5 text-gray-400 pointer-events-none left-4"
                aria-hidden="true"
              />
              <input
                type="text"
                className="w-full h-10 pl-12 text-sm border-t-0 border-b border-l-0 border-r-0 rounded-t-sm outline-none border-b-gray-200 focus:ring-0 ring-0 hover:border-b-gray-200 focus:border-b-gray-200 placeholder:text-gray-400 dark:bg-accent-500"
                value={valueSearch ?? ''}
                onChange={(e) => setValueSearch(e.target.value)}
                placeholder={placeholder || ''}
              />
            </div>
          )}
          <div className="overflow-y-auto max-h-60 flex flex-col py-1">
            {dataOptions?.map((option) => {
              const isAll = option.value === 'all'
              const isSelected =
                value.includes(option.value) ||
                (isAll && value?.length === options?.length)

              return (
                <label
                  key={option.value}
                  htmlFor={option.value}
                  className={cn(
                    'flex items-center w-full py-1.5 px-2 cursor-pointer hover:bg-gray-50 hover:text-secondary-700 focus:bg-accent focus:text-accent-foreground',
                    isSelected && 'text-secondary-700 bg-gray-50'
                  )}
                >
                  <Checkbox
                    name={option.value}
                    id={option.value}
                    variant="noRing"
                    checked={isSelected}
                    autoFocus={false}
                    onChange={() => toggleOption(option.value)}
                    className="ring-transparent focus:ring-transparent focus:"
                  >
                    {option.label}
                  </Checkbox>
                </label>
              )
            })}
            {isLoading && (
              <div className="flex flex-col gap-[2px] p-2">
                {Array.from({ length: 10 }).map((_, index) => (
                  <div className="flex items-center gap-1 p-2" key={index}>
                    <div className="w-4 h-4 skeleton" />
                    <div className="h-4 w-28 skeleton" />
                  </div>
                ))}
              </div>
            )}
            {isEmpty && (
              <div className="flex flex-col items-center justify-center h-32 gap-1 p-2">
                <div className="relative w-full h-full max-w-32">
                  <Image
                    src="/assets/icons/empty-state.svg"
                    alt="Folder Dashboard"
                    layout="fill"
                  />
                </div>
                <Typography variant="text-sm-regular" className="text-center">
                  {t?.emptyState}
                </Typography>
              </div>
            )}
          </div>
          <div
            className={cn(
              'flex justify-between items-center border-t border-gray-200 px-3 py-2'
            )}
          >
            <Button variant="neutral" size="sm" onClick={handleClickClear}>
              {t?.clear}
            </Button>
            <Button size="sm" onClick={handleClickClose}>
              {t?.close}
            </Button>
          </div>
        </PopoverContent>
      </Popover>
    </div>
  )
}

MultiSelect.displayName = 'MultiSelect'
