import React, { SetStateAction, useEffect, useMemo } from 'react'
import { TrashIcon } from '@heroicons/react/24/outline'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm, useWatch } from 'react-hook-form'
import { z } from 'zod'
import useTranslation from '@/hooks/useTranslation'
import {
  ButtonWithTooltip,
  Form,
  Modal,
  RenderFormActions,
  RenderFormField
} from '..'
import useApiTokens from '@/hooks/api/administration/user-account-accesses/useApiTokens'
import { ModalActionType } from '@/hooks/useApiTokensData/useApiTokensData.types'
import { InputTokenApi } from '@/ui/atoms'
import { Button } from '@/ui/atoms/shadcn'

type ApiKeysFormType = {
  name: string
  description: string | null
  token: string | null
}

type Props = {
  id?: string | null
  action: ModalActionType['action']
  isOpen: boolean
  onClose: () => void
  onSubmit: (
    action: ModalActionType['action'],
    values: ApiKeysFormType
  ) => Promise<void> | void
  isLoadingSubmit?: boolean
  setModalAction: React.Dispatch<SetStateAction<ModalActionType>>
}

const ModalActionApiTokens: React.FC<Props> = ({
  id,
  isOpen,
  action,
  onClose,
  onSubmit,
  isLoadingSubmit = false,
  setModalAction
}) => {
  const { t, isReady } = useTranslation(
    ['profileApiTokens', 'validations'],
    true
  )

  const { useGetApiToken } = useApiTokens()
  const { data, isLoading: isLoadingGet } = useGetApiToken(id ? id : null)

  const formSchema = z.object({
    name: z
      .string()
      .min(1, { message: t?.requiredField })
      .min(2, { message: t?.errorMinLength?.(2) })
      .max(200, { message: t?.errorMaxLength?.(200) }),
    description: z
      .string()
      .max(4000, { message: t?.errorMaxLength?.(4000) })
      .optional(),
    token: z.string().optional()
  })

  const form = useForm<ApiKeysFormType>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: '',
      description: '',
      token: ''
    }
  })
  const name = useWatch({
    control: form.control,
    name: 'name',
    defaultValue: ''
  })

  useEffect(() => {
    if ((action === 'update' || action === 'view') && data) {
      form.reset({
        name: data?.name || '',
        description: data?.description || '',
        token: data?.apiToken || ''
      })
    }
  }, [action, form, data])

  const showSkeleton = useMemo(() => {
    if (action === 'create') {
      return !isReady
    }
    if (isLoadingGet) {
      return isLoadingGet
    }
    if ((action === 'view' || action === 'update') && !name) {
      return !isReady
    }

    return !isReady ? true : false
  }, [action, isLoadingGet, isReady, name])

  return (
    <Modal
      isOpen={isOpen}
      title={t?.modal?.title?.(action)}
      onClose={onClose}
      displayAsDrawerOnMobile
    >
      <Form<ApiKeysFormType>
        {...form}
        onSubmit={(values) => onSubmit(action, values)}
      >
        <RenderFormField
          control={form.control}
          name="name"
          type="input"
          disabled={action === 'view'}
          showSkeleton={showSkeleton}
          {...form.formState.errors.name}
          {...t?.modal?.form?.name}
        />
        <RenderFormField
          control={form.control}
          name="description"
          type="textarea"
          disabled={action === 'view'}
          showSkeleton={showSkeleton}
          {...form.formState.errors.description}
          {...t?.modal?.form?.description}
        />
        {action !== 'create' && (
          <RenderFormField
            control={form.control}
            name="token"
            showSkeleton={showSkeleton}
            {...form.formState.errors.token}
            {...t?.modal?.form?.token}
            render={({ field }) => (
              <InputTokenApi
                {...field}
                showSkeleton={showSkeleton}
                autoComplete="off"
                autoCorrect="off"
                spellCheck="false"
                tooltipCopy={t?.tooltipCopy}
                tooltipHide={t?.tooltipHide}
                tooltipShow={t?.tooltipShow}
              />
            )}
          />
        )}
        <RenderFormActions align={action === 'view' ? 'between' : 'end'}>
          {action === 'create' && (
            <>
              <Button
                type="button"
                variant="neutral"
                onClick={onClose}
                showSkeleton={showSkeleton}
              >
                {t?.modal?.form?.buttonCancel}
              </Button>
              <Button
                type="submit"
                showSkeleton={showSkeleton}
                loading={isLoadingSubmit}
              >
                {t?.modal?.form?.buttonConfirm}
              </Button>
            </>
          )}
          {action === 'update' && (
            <>
              <Button
                type="button"
                variant="neutral"
                onClick={onClose}
                showSkeleton={showSkeleton}
              >
                {t?.modal?.form?.buttonCancel}
              </Button>
              <Button
                type="submit"
                showSkeleton={showSkeleton}
                loading={isLoadingSubmit}
              >
                {t?.modal?.form?.buttonConfirm}
              </Button>
            </>
          )}
          {action === 'view' && (
            <>
              <div className="flex gap-4">
                <ButtonWithTooltip
                  variant="outlineDestructive"
                  type="button"
                  onClick={() => {
                    setModalAction({
                      isOpen: true,
                      action: 'delete',
                      id: id as string
                    })
                  }}
                  showSkeleton={showSkeleton}
                  tooltipText={t?.tooltipDelete}
                >
                  <TrashIcon className="w-4 h-4" />
                </ButtonWithTooltip>
                <Button
                  type="button"
                  onClick={() => {
                    setModalAction({
                      isOpen: true,
                      action: 'update',
                      id: id as string
                    })
                  }}
                  showSkeleton={showSkeleton}
                >
                  {t?.modal?.form?.buttonUpdate}
                </Button>
              </div>
              <Button
                type="button"
                variant="neutral"
                onClick={onClose}
                showSkeleton={showSkeleton}
              >
                {t?.modal?.form?.buttonClose}
              </Button>
            </>
          )}
        </RenderFormActions>
      </Form>
    </Modal>
  )
}

export default ModalActionApiTokens
