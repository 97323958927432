import React from 'react'
import { useTheme } from 'next-themes'
import { Slide, ToastContainer } from 'react-toastify'
import { Auth, Locale, QueryParams } from '.'

type Props = {
  children: React.ReactNode
}

export const Providers: React.FC<Props> = ({ children }) => {
  const { theme } = useTheme()

  return (
    <QueryParams>
      <Auth>
        <Locale>{children}</Locale>
      </Auth>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        limit={2}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme={theme === 'dark' ? 'dark' : 'light'}
        bodyClassName="text-gray-500 font-display font-normal text-sm"
        transition={Slide}
      />
    </QueryParams>
  )
}
