import React, { useCallback, useState } from 'react'
import { CheckIcon, ClipboardIcon } from '@heroicons/react/24/outline'
import { Button } from '../shadcn'
import { cn } from '@/lib/utils'

type CopyableTextProps = {
  text: string
  icon?: React.ReactNode
  copiedText?: string
  copyText?: string
  buttonClassName?: string
}

const CopyableText: React.FC<CopyableTextProps> = ({
  text,
  icon,
  copiedText = 'Copied',
  copyText = 'Copy',
  buttonClassName = 'w-fit'
}) => {
  const [copied, setCopied] = useState(false)

  const handleCopy = useCallback(() => {
    navigator.clipboard.writeText(text)
    setCopied(true)
    setTimeout(() => setCopied(false), 2000)
  }, [text])

  return (
    <Button
      onClick={handleCopy}
      className={cn('flex items-center space-x-1', buttonClassName)}
      variant={copied ? 'secondary' : 'outlineSecondary'}
    >
      {copied ? (
        <CheckIcon className="w-5 h-5 text-green-500" />
      ) : icon ? (
        icon
      ) : (
        <ClipboardIcon className="w-5 h-5" />
      )}
      <span>{copied ? copiedText : copyText}</span>
    </Button>
  )
}

export default CopyableText
