import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import Image from 'next/image'
import Link from 'next/link'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { z } from 'zod'
import useTranslation from '@/hooks/useTranslation'
import { Form, Modal, RenderFormActions, RenderFormField } from '..'
import useAccountSustainabilityCertificatesApi from '@/hooks/api/report/useAccountSustainabilityCertificatesApi/useAccountSustainabilityCertificatesApi'
import { GetAccountSustainabilityCertificatesResponse } from '@/hooks/api/report/useAccountSustainabilityCertificatesApi/useAccountSustainabilityCertificatesApi.types'
import { Alert, CopyableText, Input, Textarea } from '@/ui/atoms'
import { Button } from '@/ui/atoms/shadcn'

type FormValues = {
  isActive: boolean
  name: string
}

type ValuesShare = {
  link: string | null
  embed: string | null
}

type Props = {
  isOpen: boolean
  onClose: () => void
  action: 'share' | 'rename' | null
  data?: GetAccountSustainabilityCertificatesResponse | null | undefined
}

const ModalAccountSustainabilityBadge: React.FC<Props> = ({
  isOpen,
  onClose,
  action,
  data
}) => {
  const { t, isReady } = useTranslation(
    ['accountSustainabilityBadge', 'validations'],
    true
  )
  const [values, setValues] = useState<ValuesShare>({
    link: null,
    embed: null
  })
  const { useUpdateAccountSustainabilityCertificates } =
    useAccountSustainabilityCertificatesApi()
  const { mutateAsync, isLoading: isLoadingUpdate } =
    useUpdateAccountSustainabilityCertificates()

  const formSchema = z.object({
    isActive: z.boolean().optional(),
    name: z
      .string()
      .min(1, { message: t?.requiredField })
      .min(2, {
        message: t?.errorMinLength?.(2)
      })
      .max(200, { message: t?.errorMaxLength?.(200) })
  })

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      isActive: false,
      name: ''
    }
  })

  useEffect(() => {
    form.reset({
      isActive: data?.isActive,
      name: data?.name
    })
  }, [data?.isActive, data?.name, form])

  const onSubmit = useCallback(
    async (values) => {
      try {
        await mutateAsync(values)
        toast.success(t?.toasts?.successRename)
      } catch (error) {
        console.log('error', error)
        toast.error(t?.toasts?.errorRename)
      } finally {
        onClose()
      }
    },
    [mutateAsync, onClose, t?.toasts?.errorRename, t?.toasts?.successRename]
  )

  const textLink = useMemo(
    () => `https://signater.com.br/selo/${data?.id}`,
    [data?.id]
  )
  const textEmbed = useMemo(
    () =>
      `<a href="${textLink}" target="_blank" rel="noreferrer"><img src="https://assets.signater.com.br/sustainability-badge/logo.webp" alt="Selo de Sustentabilidade Signater" style="width:'300px', height:'100px'"/></a>`,
    [textLink]
  )

  useEffect(() => {
    if (
      data?.id &&
      textLink &&
      textEmbed &&
      values.link === null &&
      values.embed === null
    ) {
      setValues({
        link: textLink,
        embed: textEmbed
      })
    }
  }, [textLink, textEmbed, values.link, values.embed, data?.id])

  const renderContent = useCallback(() => {
    if (!action) return null
    const mappingContent = {
      rename: (
        <Form {...form} onSubmit={onSubmit}>
          <RenderFormField
            control={form.control}
            name="name"
            type="name"
            showSkeleton={!isReady}
            {...t?.form?.name}
          />
          <RenderFormActions align="between">
            <Button
              className="w-fit"
              variant="neutral"
              type="button"
              onClick={onClose}
              disabled={isLoadingUpdate}
            >
              {t?.form?.buttonCancel?.label}
            </Button>
            <Button
              className="w-fit"
              type="submit"
              showSkeleton={!isReady}
              loading={isLoadingUpdate}
            >
              {t?.form?.buttonSubmitSave?.label}
            </Button>
          </RenderFormActions>
        </Form>
      ),
      share: (
        <div className="flex flex-col gap-5">
          <div className="flex flex-col gap-2">
            <Input
              label={t?.modalAccountSustainabilityBadge?.share?.label}
              type="text"
              value={values.link || ''}
              readOnly
            />
            <CopyableText
              text={textLink}
              copiedText={t?.copiedLink}
              copyText={t?.copyLink}
              buttonClassName="w-full"
            />
          </div>
          <div className="flex flex-col gap-2">
            <Textarea
              label={t?.modalAccountSustainabilityBadge?.formEmbed?.label}
              value={values.embed || ''}
              className="h-44"
              readOnly
            />
            <CopyableText
              text={textEmbed}
              copiedText={t?.copiedEmbed}
              copyText={t?.copyEmbed}
              buttonClassName="w-full"
            />
          </div>
          <Link href={textLink} passHref>
            <a target="_blank" rel="noreferrer" className="mx-auto w-fit">
              <Image
                src="https://assets.signater.com.br/sustainability-badge/logo.webp"
                alt="Selo de Sustentabilidade"
                width={300}
                height={100}
                // className="h-[100px] w-[300px]"
                title={t?.modalAccountSustainabilityBadge?.share?.titleImg}
              />
            </a>
          </Link>
          {data && !data?.isActive && (
            <Alert
              variant="info"
              title={t?.modalAccountSustainabilityBadge?.share?.alert}
            />
          )}
        </div>
      )
    }

    return mappingContent[action as keyof typeof mappingContent]
  }, [
    action,
    data,
    form,
    isLoadingUpdate,
    isReady,
    onClose,
    onSubmit,
    t?.copiedEmbed,
    t?.copiedLink,
    t?.copyEmbed,
    t?.copyLink,
    t?.form?.buttonCancel?.label,
    t?.form?.buttonSubmitSave?.label,
    t?.form?.name,
    t?.modalAccountSustainabilityBadge?.formEmbed?.label,
    t?.modalAccountSustainabilityBadge?.share?.alert,
    t?.modalAccountSustainabilityBadge?.share?.label,
    t?.modalAccountSustainabilityBadge?.share?.titleImg,
    textEmbed,
    textLink,
    values.embed,
    values.link
  ])

  return (
    <Modal
      isOpen={isOpen}
      title={t?.modalAccountSustainabilityBadge?.title?.(action)}
      onClose={onClose}
      // size="sm"
      displayAsDrawerOnMobile
    >
      {renderContent()}
    </Modal>
  )
}

export default ModalAccountSustainabilityBadge
